import { createContext, ReactNode, useContext } from "react";
import { useNavigationType, NavigationType } from "react-router-dom";

type NavigationContextType = {
    navigationType: NavigationType
}

const GlobalNavigationContext = createContext<NavigationContextType>(null!);

export const GlobalNavigationProvider = ({ children }: { children?: ReactNode }) => {
    const navigationType = useNavigationType();

    return (
        <GlobalNavigationContext.Provider value={{
            navigationType
        }}
        >
            {children}
        </GlobalNavigationContext.Provider>
    );
}

export const useGlobalNavigationProvider = () => useContext(GlobalNavigationContext)