import { isValidRedfinUrl } from "components/hostedScrapingValidators";
import { SDEContent } from "./sdecontentTypes";

export const RedfinForSaleContent: SDEContent = {
  projectType: 'async_redfin_forsale',
  projectCategory: 'redfin',
  apiUrlFn: (apiHost: string) => {
    return `${apiHost}/structured/redfin/forsale`;
  },
  docsSection: 'redfin-for-sale-listings-api',
  docsSectionTitle: 'Redfin \'For Sale\' Listings API',
  docsSectionAsync: 'redfin-for-sale-listings-api-async',
  docsSectionAsyncTitle: 'Redfin \'For Sale\' Listings API (Async)',
  inputKey: 'url',
  inputsKey: 'urls',
  validatorFunction: isValidRedfinUrl,
  newProjectEditProject: {
    inputBoxLabels: {
      listLiteralInputTitle: 'URL',
      listLiteralInputPlaceholder: 'Enter one URL per line',
    },
    inputSectionLabels: {
      inputSectionTitle: "URL",
      inputSectionDescription: "Enter URLs in the input field or upload CSV with your URLs to begin scraping Redfin.",
    },
    projectContent: {
      title: 'Redfin property pages - for sale',
      description: 'Extract details of real estate properties for sale on Redfin. Download the results in a well-structured JSON file.',
      icon: 'redfin',
      inputTitle: 'URL',
      },
  },
  apiPlayground: {
    inputSectionLabels: {
      inputSectionTitle: 'Property page URL',
      inputSectionDescription: 'The URL of the property\'s page. Real estates for sale.',
    },
    projectContent: {
      title: 'Redfin property pages - real estate for sale',
      description: 'Extract details of properties for sale on Redfin.',
      icon: 'redfin',
    },
    enterInputsPlaceholder: 'https://redfin.com/NH/Salem/1-Chestnut-Dr-03079/home/96531263',
    enterInputsMessage: 'Enter the URL path of the property\'s page',
    codeViewPlaceHolder: 'Please provide a valid search query',
    dropdownTitle: 'Redfin - properties for sale'
  }
};
