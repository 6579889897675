export const AdditionalOptionsTextBox = ({type, value, placeholder, callback}:{type?: string, value: string | number | undefined, placeholder: string, callback: (value: string|undefined) => void}) => {
  return (<div className="w-full">
    <input type={type ?? 'text'} className="w-full border border-lightest shadow placeholder-gray-200 dark:placeholder-neutral-200 text-sm p-2" placeholder={placeholder} value={value} onChange={(ev) => {
      const newValue = ev.target.value;
      if (newValue === "") {
        callback(undefined);
      } else {
        callback(newValue);
      }
    }} />
  </div>);
};
