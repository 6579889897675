import { useState } from "react";
import { useParams } from "react-router-dom";
import Modal, { useCloseModal } from ".";
import scraperApi, { API_HOST }  from "api";

import Button from "components/Button";
import { useEffect } from "react";
import { FullWidthLoadingSpinner } from "components/FullWidthLoadingSpinner";
import { Job, useHostedScrapingProjects } from "providers/HostedScrapingProvider";
import Toaster from "components/Toaster";
import { formatDate } from "components/hosted-scraping/ProjectFormatDate";
import Alert from "components/Alert";
import { NewProjectConfig, countOfAllTasks } from "providers/HostedScrapingProvider/types";
import { AmazonProjectDetails, CostRow, EbayProjectDetails, GoogleProjectDetails, Separator, SummaryRow, UrlProjectDetails, WalmartProjectDetails, RedfinProjectDetails, textsHS } from "components/hosted-scraping/project-summary/ProjectSummaryComponents";
import { schedulingInterval } from "components/hosted-scraping/showProjectConfig";
import { isAmazonProject, isAsyncUrlsProject, isEbayProject, isGoogleProject, isWalmartProject, isRedfinProject, projectTypeHS, projectTypeToInputTitleHS } from "sdecontent";

type ErrorReport = {
  error: string;
  input: string;
}[];

const ErrorReportTable = ({errorReport, inputTitle}: {errorReport: ErrorReport; inputTitle: string}) => {
  return (
    <div className="flex flex-col w-full">
      <table className="table-auto w-full border shrink grow-0 bg-lightestGray dark:bg-neutral-50">
        {/* <thead className="sticky top-0 bg-white border"> */}
        <thead className="">
          <tr className="h-6">
            <th className="px-4 py-2 border">Error</th>
            <th className="px-4 py-2 border">{inputTitle}</th>
          </tr>
        </thead>
        <tbody className="overflow-y-scroll">
          {errorReport.map((line, index) => (
            <tr key={index} className="h-6">
              <td className="border px-4 py-2 h-6">{line.error}</td>
              <td className="border px-4 py-2 h-6">{line.input}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* <div className="shrink grow" /> */}
    </div>
  );
}

export function HostedScraperErrorReportModal() {
  const goBack = useCloseModal();
  const { projects } = useHostedScrapingProjects();
  const { publicId, projectId } = useParams() as { publicId: string; projectId: string };
  const [ inProgress, setInProgress ] = useState<boolean>(false);
  const [ errorReport, setErrorReport ] = useState<ErrorReport | undefined>(undefined);

  useEffect(() => {
    const fetchReport = async() => {
      setInProgress(true);
      try {
        const errorReportResponse = await scraperApi.hostedScraping.errorReport(publicId);
        setErrorReport(errorReportResponse);
      } finally {
        setInProgress(false);
      }
    }
    fetchReport();
  }, [publicId]);

  const project = projects.find(p => p.id === Number(projectId));
  if (project === undefined) {
    return <div>Project not found</div>;
  }
  const inputTitle = projectTypeToInputTitleHS(project.config.type);

  const rawReportUrl = `${API_HOST}/hostedscraping/job/${publicId}/errorreport`;

  return (
    <Modal headline="Error report" onClose={goBack} closeOnEscapeKey={true}>
      <div className="flex-col p-2 gap-y-12 overflow-hidden">
        <div className="grow-0 shrink flex gap-y-4 text-gray dark:text-neutral-600 overflow-auto h-96">
          {inProgress && <FullWidthLoadingSpinner />}
          {!inProgress && <ErrorReportTable inputTitle={inputTitle} errorReport={errorReport ?? []} />}
        </div>
        <div className="grow-0 shrink-0 flex justify-end items-center gap-x-4 h-14 pt-2">
          <Button text="Download raw JSON report" className="button button-secondary" href={rawReportUrl} size="MD" />
          <Button text="Close" className="button button-secondary" onClick={goBack} size="MD" />
        </div>
      </div>
    </Modal>
  );
}

/*
 * Note this is not a usual modal that is tied to a URL. It's a normal component
 * that accepts input as a property.
 */
interface IHostedScraperCancelJobModalProps {
  projectId: number;
  jobId: number;
  onClose: () => void;
}

export function HostedScraperCancelJobModal({projectId, jobId, onClose}: IHostedScraperCancelJobModalProps) {
  // const goBack = useCloseModal();
  // const { projectId, jobId } = (useParams() as unknown) as { projectId: number, jobId: number };

  const cancelJob = async () => {
    onClose();
    try {
      await scraperApi.hostedScraping.cancelJob(projectId, jobId);
      Toaster.success("Job cancelled successfully");
    } catch (err) {
      Toaster.error("Failed to cancel the job");
      console.error(err);
    }
  }

  return (
    <Modal headline="Cancel job" onClose={onClose} closeOnEscapeKey={true}>
      <div className="flex flex-col p-5 gap-y-12">
        <div className="text-gray dark:text-neutral-600">
          <span>
            Cancelling the scraping job will stop the ongoing task and may result in the loss of unsaved data. Please note that the
            credits for the successful responses of this job will be charged upon cancelation.
          </span>
        </div>
        <div className="text-gray dark:text-neutral-600 font-bold">
          <span className="font-bold">
            Are you sure you want to cancel the job?
          </span>
        </div>
        <div className="flex justify-end gap-x-4 item-center">
          <Button text="Cancel Job" className="button button-primary" onClick={cancelJob} size="MD" />
          <Button text="Close" className="button button-secondary" onClick={onClose} size="MD" />
        </div>
      </div>
    </Modal>
  );
}

/*
 * Note this is not a usual modal that is tied to a URL. It's a normal component
 * that accepts input as a property.
 *
 * The reason for this is that the usual modal gets the parameters for contexts,
 * but you don't want to have a context to show the actual job because the
 * changing job would cause the whole page to re-render.
 */
interface IHostedScraperJobInfoModalProps {
  jobList: null | Job[];
  jobId: number;
  onClose: () => void;
}

export function HostedScraperJobInfoModal({jobList, jobId, onClose}: IHostedScraperJobInfoModalProps) {

  if (jobList === null) {
    onClose();
    return null;
  }

  const job = jobList.find(j => j.id === jobId);
  if (job === undefined || job.status !== 'running') {
    onClose();
    return null;
  }

  const failedResponseCount = (job?.inProgressTasksWithAttempts || 0) + (job?.failedTasks || 0);
  const attemptsCount = (job?.asyncAttempts || 0);

  return (
    <Modal headline="Job progress details" onClose={onClose} closeOnEscapeKey={true}>
      <div className="flex flex-col p-5 gap-y-12">
        <ul className="list-disc list-inside">
          <li>Job started: <b>{formatDate(job?.createdAt)}</b></li>
          <li>Total responses to be processed: <b>{countOfAllTasks(job)}</b></li>
          <li>Completed responses: <b>{job?.successfulTasks}</b></li>
          {/* <li>Timed out responses: <b>{job?.timedoutTasks}</b></li> */}
          <li>Failed responses: <b>{failedResponseCount}</b></li>
          <li>Failed response re-attempts: <b>{attemptsCount}</b></li>
        </ul>
        {
          job?.nextRetry !== undefined && job?.nextRetry !== null
          ? (<>
            <Alert description="Our system retries Failed URLs to ensure data accuracy. This process continues until all URLs are scraped or the job times out."/>
            <ul className="list-disc list-inside">
              <li>Next Attempt Time: {formatDate(job.nextRetry)}</li>
            </ul>
          </>)
          : null
        }
        <div className="flex justify-end gap-x-4 item-center">
          <Button text="Close" className="button button-secondary" onClick={onClose} size="MD" />
        </div>
      </div>
    </Modal>
  );
}

/*
 * Note this is not a usual modal that is tied to a URL. It's a normal component
 * that accepts a project as a property.
 */
interface IHostedScraperReviewProjectModalProps {
  project: NewProjectConfig;
  cost: number | undefined;
  costInProgress: boolean;
  onStartScraping: () => void;
  onClose: () => void;
}

export function HostedScraperReviewProjectModal({project, cost, costInProgress, onStartScraping, onClose}: IHostedScraperReviewProjectModalProps) {
  const projectTypeTitle = projectTypeHS(project.config.type);
  const schedulingIntervalTitle = project.enabled
    ? schedulingInterval(project.scrapingInterval)
    : !project.enabled && Boolean(project.supposedToRunAt)
    ? { text: 'Once' }
    : { text: '-' };

  return (
    <Modal headline="Review & Start Scraping" onClose={onClose} closeOnEscapeKey={true}>
      <div className="flex flex-col p-5 gap-y-6">
        <div className="text-gray dark:text-neutral-600">
          <span>You're about to start the scraping project. Please review your settings and click on "Start scraping" to trigger your first job.</span>
        </div>
        <div className="p-3 bg-lightestGray dark:bg-neutral-50 text-gray dark:text-neutral-600">


          <SummaryRow title="Type:" value={projectTypeTitle} />
          <SummaryRow title="Scraping frequency:" value={schedulingIntervalTitle.text} />
          { isAsyncUrlsProject(project.config.type)
            && <UrlProjectDetails collectorConfig={project.config} />}
          { isAmazonProject(project.config.type)
            && <AmazonProjectDetails collectorConfig={project.config} />}
          { isGoogleProject(project.config.type)
            && <GoogleProjectDetails collectorConfig={project.config} />}
          { isWalmartProject(project.config.type)
            && <WalmartProjectDetails collectorConfig={project.config} />}
          { isEbayProject(project.config.type)
            && <EbayProjectDetails collectorConfig={project.config} />}
          { isRedfinProject(project.config.type)
            && <RedfinProjectDetails collectorConfig={project.config} />}

          <Separator/>
          <CostRow texts={textsHS} cost={cost} costInProgress={costInProgress} />
        </div>
        <div className="flex justify-end gap-x-4 item-center">
          <Button text="Cancel" className="button button-tertiary" onClick={onClose} size="MD" />
          <Button text="Start scraping" className="button button-primary" onClick={onStartScraping} size="MD" />
        </div>
      </div>
    </Modal>
  );
}
