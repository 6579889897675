import { isValidRedfinUrl } from "components/hostedScrapingValidators";
import { SDEContent } from "./sdecontentTypes";

export const RedfinForRentContent: SDEContent = {
  projectType: 'async_redfin_forrent',
  projectCategory: 'redfin',
  apiUrlFn: (apiHost: string) => {
    return `${apiHost}/structured/redfin/forrent`;
  },
  // Note: At the time of writing the docs were missing so I just assume their section's name
  // TODO docs are still missing
  docsSection: 'redfin-property-for-rent-api',
  docsSectionTitle: '-',
  docsSectionAsync: 'redfin-property-for-rent-api-async',
  docsSectionAsyncTitle: '-',
  inputKey: 'url',
  inputsKey: 'urls',
  validatorFunction: isValidRedfinUrl,
  newProjectEditProject: {
    inputBoxLabels: {
      listLiteralInputTitle: 'URL',
      listLiteralInputPlaceholder: 'Enter one URL per line',
    },
    inputSectionLabels: {
      inputSectionTitle: "URL",
      inputSectionDescription: "Enter URLs in the input field or upload CSV with your URLs to begin scraping Redfin.",
    },
    projectContent: {
      title: 'Redfin property pages - rental',
      description: 'Extract details of real estate properties for rent on Redfin. Download the results in a well-structured JSON file.',
      icon: 'redfin',
      inputTitle: 'URL',
      },
  },
  apiPlayground: {
    inputSectionLabels: {
      inputSectionTitle: 'Property page URL',
      inputSectionDescription: 'The URL of the property\'s page. Real estates for rent.',
    },
    projectContent: {
      title: 'Redfin rentals',
      description: 'Extract details of properties for rent on Redfin.',
      icon: 'redfin',
    },
    enterInputsPlaceholder: 'https://www.redfin.com/MD/Baltimore/Ednor-I-Apartments/apartment/11891543',
    enterInputsMessage: 'Enter the URL path of the property\'s page',
    codeViewPlaceHolder: 'Please provide a valid search query',
    dropdownTitle: 'Redfin - properties for rent'
  }
};
