export const async_ebay_search = {
  input: "iPhone",
  result: "[\n" +
    "  {\n" +
    "    \"product_title\": \"Apple iPhone SE 2nd Gen (Great Cond) Unlocked AT&T TMobile Verizon 64 128 256 GBOpens in a new window or tab\",\n" +
    "    \"image\": \"https://i.ebayimg.com/images/g/GJMAAOSw3bBmaHw2/s-l500.webp\",\n" +
    "    \"product_url\": \"https://www.ebay.com/itm/226358814412\",\n" +
    "    \"condition\": \"Pre-Owned\",\n" +
    "    \"item_price\": {\n" +
    "      \"from\": {\n" +
    "        \"value\": 147.99,\n" +
    "        \"currency\": \"USD\"\n" +
    "      },\n" +
    "      \"to\": {\n" +
    "        \"value\": 264.99,\n" +
    "        \"currency\": \"USD\"\n" +
    "      }\n" +
    "    },\n" +
    "    \"extra_info\": \"Buy It Now\",\n" +
    "    \"free_returns\": \"Free returns\",\n" +
    "    \"watchers\": 19,\n" +
    "    \"seller_name\": \"cocosprinkles\",\n" +
    "    \"seller_rating_count\": 32530,\n" +
    "    \"seller_rating\": 99.8,\n" +
    "    \"seller_has_top_rated_plus\": true\n" +
    "  },\n" +
    "  {\n" +
    "    \"product_title\": \"Apple iPhone 8 (Great Condition) Unlocked AT&T Verizon T-Mobile 64GB 128GB 256GBOpens in a new window or tab\",\n" +
    "    \"image\": \"https://i.ebayimg.com/images/g/NXoAAOSwNIdmZw4e/s-l500.webp\",\n" +
    "    \"product_url\": \"https://www.ebay.com/itm/226358808865\",\n" +
    "    \"condition\": \"Pre-Owned\",\n" +
    "    \"item_price\": {\n" +
    "      \"from\": {\n" +
    "        \"value\": 134.99,\n" +
    "        \"currency\": \"USD\"\n" +
    "      },\n" +
    "      \"to\": {\n" +
    "        \"value\": 214.99,\n" +
    "        \"currency\": \"USD\"\n" +
    "      }\n" +
    "    },\n" +
    "    \"extra_info\": \"Buy It Now\",\n" +
    "    \"free_returns\": \"Free returns\",\n" +
    "    \"items_sold\": 59,\n" +
    "    \"seller_name\": \"cocosprinkles\",\n" +
    "    \"seller_rating_count\": 32530,\n" +
    "    \"seller_rating\": 99.8,\n" +
    "    \"seller_has_top_rated_plus\": true\n" +
    "  },\n" +
    "  {\n" +
    "    \"product_title\": \"Apple iPhone SE 2nd Gen 2020 64GB Unlocked Verizon At&t T-Mobile Fair ConditionOpens in a new window or tab\",\n" +
    "    \"image\": \"https://i.ebayimg.com/images/g/gg4AAOSw735mRYIO/s-l140.webp\",\n" +
    "    \"product_url\": \"https://www.ebay.com/itm/226378701013\",\n" +
    "    \"condition\": \"Pre-Owned · Apple iPhone SE (2nd Generation) · 64 GB · Unlocked\",\n" +
    "    \"item_price\": {\n" +
    "      \"value\": 90,\n" +
    "      \"currency\": \"USD\"\n" +
    "    },\n" +
    "    \"extra_info\": \"Buy It Now\",\n" +
    "    \"shipping_cost\": \"Free shipping\",\n" +
    "    \"watchers\": 10,\n" +
    "    \"seller_name\": \"esupplytech\",\n" +
    "    \"seller_rating_count\": 4156,\n" +
    "    \"seller_rating\": 97.8\n" +
    "  },\n" +
    "  {\n" +
    "    \"product_title\": \"Apple iPhone XR Black 128GB A1984 MT362LL/A Verizon Clean ESN Good (JF)Opens in a new window or tab\",\n" +
    "    \"image\": \"https://i.ebayimg.com/images/g/GAgAAOSwxDRitG-z/s-l140.webp\",\n" +
    "    \"product_url\": \"https://www.ebay.com/itm/195161489925\",\n" +
    "    \"condition\": \"Good - Refurbished · Apple iPhone XR · 128 GB · Verizon\",\n" +
    "    \"item_price\": {\n" +
    "      \"value\": 143.96,\n" +
    "      \"currency\": \"USD\"\n" +
    "    },\n" +
    "    \"extra_info\": \"Buy It Now\",\n" +
    "    \"free_returns\": \"Free returns\",\n" +
    "    \"seller_name\": \"auditmacstech\",\n" +
    "    \"seller_rating_count\": 12440,\n" +
    "    \"seller_rating\": 99.2,\n" +
    "    \"seller_has_top_rated_plus\": true\n" +
    "  },\n" +
    "]"
};
