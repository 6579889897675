import { ReactElement, ReactNode, useCallback, useEffect, useState } from "react";
import { PencilIcon } from "@heroicons/react/outline";
import { NavLink } from "react-router-dom";

import BorderedPage from "components/BorderedPage";

import BorderLayout from "layouts/BorderLayout";

import { PROJECTS_TITLE } from "routes/dashboard/BeautifulProjectsView";


const NEW_PROJECT = 'New Project';

const EditableBreadcrumbPageTitle = ({ projectName, callback }: { projectName?: string, projectId?: number, callback: (newProjectName: string) => void}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedProjectName, setEditedProjectName] = useState(projectName);
  const [originalProjectName, setOriginalProjectName] = useState(projectName);

  const editingFinished = useCallback( () => {
    setIsEditing(false);
    if (editedProjectName) {
      setOriginalProjectName(editedProjectName);
      callback(editedProjectName);
    }

  }, [callback, editedProjectName]);

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      editingFinished();
    } else if (e.key === 'Escape') {
      setIsEditing(false);
      setEditedProjectName(originalProjectName);
    }
  }, [editingFinished, originalProjectName, setIsEditing, setEditedProjectName]);

  useEffect(() => {
    if (isEditing) window.addEventListener('keydown', handleKeyDown);
    else window.removeEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isEditing, handleKeyDown]);

  return (
    <div className="text-2xl flex flex-row flex-wrap gap-2 overflow-auto">
      <NavLink to="/projects">
        <div className="text-neutral-500 dark:text-neutral-500">{PROJECTS_TITLE}</div>
      </NavLink>
      <div className="text-gray dark:text-neutral-600">/</div>
      <div className="shrink">
        {
          isEditing
          ? (<input autoFocus className="border border-slate-200" value={editedProjectName} onChange={(e) => setEditedProjectName(e.target.value)} onBlur={editingFinished}/>)
          : (<div className="flex flex-row items-center" onClick={() => setIsEditing(true)}>
            <div className="text-gray dark:text-neutral-600">{projectName || NEW_PROJECT}</div>
            <div className="w-5 h-5 text-neutral-500 dark:text-neutral-500 ml-2.5"><PencilIcon/></div>
          </div>)
        }
      </div>
    </div>
  )
};

const BreadcrumbPageTitle = ({ projectName, buttons }: { projectName?: string, buttons?: ReactElement<any, any>}) => {
  return (
    <div className="flex items-center justify-between w-full">
      <div className="text-2xl flex flex-row flex-wrap gap-2 overflow-auto">
        <NavLink to="/projects">
          <div className="text-neutral-500 dark:text-neutral-500">{PROJECTS_TITLE}</div>
        </NavLink>
        <div className="text-neutral-500 dark:text-neutral-500">/</div>
        <div>{projectName || NEW_PROJECT}</div>
      </div>
      <div>
        {buttons}
      </div>
    </div>
  )
};

// View mode
export function ProjectBreadcrumbPage(props: {
  children: ReactNode;
  projectName?: string;
  projectId?: number;
  rightSidebar?: ReactElement<any, any> | ReactElement<any, any>[];
  actionButtons?: ReactElement<any, any> | ReactElement<any, any>[];
  readonly: true; // Read only, no callback
  titleButtons: ReactElement<any, any>; // Read only: reschedule,
  className?: string;
}): JSX.Element;

// Edit mode
export function ProjectBreadcrumbPage(props: {
  children: ReactNode;
  projectName?: string;
  projectId?: number;
  rightSidebar?: ReactElement<any, any> | ReactElement<any, any>[];
  actionButtons?: ReactElement<any, any> | ReactElement<any, any>[];
  readonly?: false; // Not readonly, callback is required
  callback: (newProjectName: string) => void;
  className?: string;
}): JSX.Element;

export function ProjectBreadcrumbPage({
  children,
  projectName,
  projectId,
  rightSidebar,
  actionButtons,
  readonly,
  callback,
  className,
  titleButtons,
}: {
  children: ReactNode;
  projectName?: string;
  projectId?: number;
  rightSidebar?: ReactElement<any, any> | ReactElement<any, any>[];
  actionButtons?: ReactElement<any, any> | ReactElement<any, any>[];
  readonly?: boolean;
  callback?: (newProjectName: string) => void;
  className?: string;
  titleButtons?: ReactElement<any, any>;
}): JSX.Element {

  const title = `${PROJECTS_TITLE} / ${projectName || NEW_PROJECT}`;

  return (
    <BorderLayout
      layout="horizontal"
      wrap="xl"
      bottom={ actionButtons && (
          <div className="px-8 py-5 space-y-5">
            <div className="w-full border-t border-slate-200"/>
            { actionButtons }
          </div>
        )
      }
      right={ rightSidebar && <div className="w-full xl:w-80 xl:h-full">{ rightSidebar }</div> }
    >
      <BorderedPage
        title={ readonly ?
          <BreadcrumbPageTitle projectName={ projectName } buttons={ titleButtons } /> :
          <EditableBreadcrumbPageTitle projectName={ projectName } projectId={ projectId } callback={ callback! } />
        }
        browserTitle={ title }
      >
        { children }
      </BorderedPage>
    </BorderLayout>
  );
}
