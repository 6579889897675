import { useMemo } from "react";

import { sdeDescriptors } from "sdecontent";

import ResourceCard, { Bold, Title } from "./ResourceCard";
import { useQuickStartContext } from "./QuickStartCard";


export default function SDEResources() {
  const { programmingLanguage, sdeProjectType } = useQuickStartContext();

  const sdeDescriptor = useMemo(() => {
    return sdeDescriptors.find(desc => desc.projectType === sdeProjectType);
  }, [ sdeProjectType ]);

  return (
    <>
      <ResourceCard
        title={ <Title>Get started with the <Bold>API Method</Bold></Title> }
        linkHref={ `https://docs.scraperapi.com/${ programmingLanguage }/making-requests/structured-data-collection-method/${sdeDescriptor?.docsSection}` }
        tracked
        trackedTitle={ sdeDescriptor?.docsSectionTitle }
      />

      <ResourceCard
        title={ <Title>Use the <Bold>Async Method</Bold></Title> }
        linkHref={ `https://docs.scraperapi.com/${programmingLanguage}/making-requests/async-structured-data-collection-method/${sdeDescriptor?.docsSectionAsync}` }
        tracked
        trackedTitle={ sdeDescriptor?.docsSectionAsyncTitle }
      />

      <ResourceCard
        title={ <Title>See all available <Bold>Structured Data Endpoints</Bold></Title> }
        linkHref={ `https://docs.scraperapi.com/${programmingLanguage}/making-requests/structured-data-collection-method` }
        tracked
        trackedTitle="Structured Data Collection Method"
      />

      <ResourceCard
        title={ <Title>Let us <Bold>host your Structured Data requests</Bold></Title> }
        linkHref={ `https://docs.scraperapi.com/datapipeline/how-to-create-a-new-datapipeline-project/input-and-parameters#input-for-use-case-specific-project-types` }
        tracked
        trackedTitle="Input for use case specific project types"
      />
    </>
  );
};
