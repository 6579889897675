import { useMemo } from "react";

import { ReactComponent as ScraperAPIIllustration4 } from "assets/images/scraperapi-illustration4.svg";

import { fmtDate } from "utils";

import Spinner from "components/Spinner";
import UsageBar from "components/UsageBar";

import { useUser } from "routes/dataroutes/UserData";


interface IUsageChartProps {
  creditsResetOn?: Date;
  usedCredits: number;
  totalCredits: number;
  usedConcurrentRequests: number;
  totalConcurrentRequests: number;
}


export default function UsageChart({
                                     creditsResetOn,
                                     usedCredits = 0,
                                     totalCredits = 0,
                                     usedConcurrentRequests = 0,
                                     totalConcurrentRequests = 0
                                   }: IUsageChartProps) {
  const user = useUser();
  const readableDate = useMemo(() => creditsResetOn ? fmtDate(creditsResetOn) : undefined, [ creditsResetOn ]);

  let creditUsageRatio = 0;
  if (totalCredits !== 0) {
    creditUsageRatio = usedCredits / totalCredits;
  }

  let usageBgColor = 'bg-brandPrimary dark:bg-primary-600';
  if (creditUsageRatio >= 0.9) {
   usageBgColor = 'bg-red dark:bg-error-600';
  } else if (creditUsageRatio >= 0.7) {
   usageBgColor = 'bg-orange dark:bg-warning-600';
  }

  return (
    <div className="p-8 bg-white border border-borderColor dark:border-neutral-200">
      <div className="flex justify-between gap-x-16">
        <div className="flex flex-col justify-between grow">
          <span className="text-gray dark:text-neutral-600">Usage</span>

          <div className="space-y-5">
            <UsageBar
              label="concurrent requests"
              actual={usedConcurrentRequests}
              total={totalConcurrentRequests}
            />
            <UsageBar
              label="API credits"
              actual={usedCredits}
              total={totalCredits}
              bgColor={usageBgColor}
            >
              <div className="text-xs text-gray dark:text-neutral-600">
                { user?.isBlocked && <>Your account is currently blocked</> }
                { !user?.isBlocked && <div className="flex flex-row gap-x-2">Resets on { readableDate ? readableDate : <Spinner size={ 12 } theme="secondary" className="!text-neutral-500" /> }</div> }
              </div>
            </UsageBar>
          </div>
        </div>

        <div className="self-center hidden xl:block px-8">
          <ScraperAPIIllustration4 className="max-w-[157px] w-full"/>
        </div>
      </div>
    </div>
  );
};
