import { MouseEvent, MutableRefObject } from "react";
import { Link, useRevalidator } from "react-router-dom";

import scraperApi from "api";

import Button from "components/Button";
import CopyToClipboardText from "components/CopyToClipboardText";
import StatefulLink from "components/StatefulLink";

import { useNewDashboardDesign } from "providers/NewDashboardDesignProvider";

import { useUser } from "routes/dataroutes/UserData";
import Menu, { MenuButton, MenuItems } from "components/Menu";
import { MenuItem } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { trackBackToOldDesignOptionClicked } from "utils/Tracking";


interface IOnClickProps {
  onClick?: (event?: MouseEvent) => void;
  refUserMenu?: MutableRefObject<HTMLDivElement | null>;
}

function UserMenuItem(
  {
    label,
    item
  }: {
    label?: string;
    item: string | JSX.Element;
  }
) {
  return (
    <div className="flex flex-col text-sm px-5 py-3 space-y-2">
      {label && <span className="text-neutral-500 dark:text-neutral-500">{label}</span>}
      {typeof item === "string" ? <span className="text-gray dark:text-neutral-600">{item}</span> : item}
    </div>
  );
}

export function AccountEmail() {
  const user = useUser();

  return <UserMenuItem
    label="Account Email"
    item={user?.email || ""}
  />;
}

export function AccountApiKey() {
  const user = useUser();

  return <UserMenuItem
    label="API Key"
    item={<CopyToClipboardText text={user?.apiKey || ""} />}
  />;
}

export function ChangePassword({ onClick }: IOnClickProps) {
  const user = useUser();

  return (
    <StatefulLink
      to="/change-password"
      addLocationToState
      className="block w-full transition-colors"
      hoverClassName="hover:bg-slate-200"
      onClick={onClick}
    >
      <UserMenuItem item={user?.hasPassword ? "Change Password" : "Set Password"} />
    </StatefulLink>
  );
}

export function ManageApiKeys({ onClick }: IOnClickProps) {
  const user = useUser();

  return (
    <StatefulLink
      to="/api-keys"
      addLocationToState
      className={"block w-full transition-colors"}
      hoverClassName="hover:bg-slate-200"
      disabled={user?.isBlocked || false}
      onClick={onClick}
    >
      <UserMenuItem item="Manage API Keys" />
    </StatefulLink>
  );
}

export function UseOldDashboardDesign({ onClick }: IOnClickProps) {
  const { updateNewDashboardDesignSetting } = useNewDashboardDesign();

  return (
    <button
      className="w-full transition-colors hover:bg-slate-200 flex justify-start"
      onClick={(event?: MouseEvent) => {
        updateNewDashboardDesignSetting("no");
        trackBackToOldDesignOptionClicked("yes");
        onClick?.(event);
      }}
    >
      <UserMenuItem item="Back to old design" />
    </button>
  );
}

export function Logout({ onClick, refUserMenu }: IOnClickProps) {
  const revalidator = useRevalidator();

  const logoutOnClick = async (logoutAllDevices?: boolean) => {
    onClick?.();
    await scraperApi.auth.logout({ logoutAllDevices });
    revalidator.revalidate();
  }

  return (
    <div ref={refUserMenu}>
      <UserMenuItem
        item={
          (
            <>
              <div className="relative w-full flex items-stretch flex-shrink-0 justify-center">
                <Button
                  text="Logout"
                  className="button button-primary"
                  size="SM"
                  fullWidth
                  onClick={async () => await logoutOnClick()}
                />
                <Menu>
                  <MenuButton className="button button-primary px-3 flex items-center data-[open]:rotate-180">
                      <ChevronDownIcon
                        className="w-5 h-5 text-white group-hover:text-white"
                        aria-hidden="true"
                      />
                  </MenuButton>
                  <MenuItems
                    anchor={{ to: "top end", padding: "3rem", gap: "0.5rem" }}
                    className="z-40"
                  >
                    <MenuItem>
                      <>
                        <Link
                          to={`/`}
                          onClick={async () => {
                            const logoutAllDevices = true;
                            await logoutOnClick(logoutAllDevices);
                          }}
                          className="z-40 group !capitalize flex w-full items-center p-2 text-sm hover:bg-brandPrimary/50 dark:hover:bg-primary-600/50 hover:text-white text-gray-900 dark:text-neutral-900"
                        >
                          Logout from all devices
                        </Link>
                      </>
                    </MenuItem>
                  </MenuItems>
                </Menu>
              </div>
            </>
          )
        }

      />
    </div>
  )
}