import {
  RiCodeBoxLine,
  RiJavaLine,
  RiNodejsLine,
  RiPhpLine,
  RiCurrencyLine
} from "@remixicon/react";

import { ReactComponent as BillingIcon } from "assets/icons/billing-icon.svg";
import { ReactComponent as DashboardIcon } from "assets/icons/dashboard-icon.svg";
import { ReactComponent as DataPipelineIcon } from "assets/icons/data-pipeline.svg";
import { ReactComponent as DocumentIcon } from "assets/icons/document-icon.svg";
import { ReactComponent as HeadsetIcon } from "assets/icons/headset-icon.svg";
import { ReactComponent as LifebuoyIcon } from "assets/icons/lifebuoy-icon.svg";
import { ReactComponent as PulseIcon } from "assets/icons/pulse-icon.svg";

import { ReactComponent as AmazonIcon } from "assets/icons/amazon-icon.svg";
import { ReactComponent as EbayIcon } from "assets/icons/ebay-icon.svg";
import { ReactComponent as FunnelIcon } from "assets/icons/funnel-icon.svg";
import { ReactComponent as GoogleIcon } from "assets/icons/google-icon.svg";
import { ReactComponent as RawUrlsIcon } from "assets/icons/raw-urls-icon.svg";
import { ReactComponent as ScraperAPILogoNoText } from "assets/icons/scraperapi-logo-no-text.svg";
import { ReactComponent as WalmartIcon2 } from "assets/icons/walmart-icon_2.svg";
import { ReactComponent as RedfinIcon } from "assets/icons/redfin-icon.svg";
import { ReactComponent as XIcon } from "assets/icons/x-icon.svg";

import { ReactComponent as CurlIcon } from "assets/icons/curl-icon.svg";
import { ReactComponent as PythonIcon } from "assets/icons/python-icon.svg";
import { ReactComponent as RubyIcon } from "assets/icons/ruby-icon.svg";


const Icons = {
  // internal pages, sidebar links
  APIPlayground: RiCodeBoxLine,
  Billing: BillingIcon,
  Dashboard: DashboardIcon,
  DataPipeline: DataPipelineIcon,
  Documentation: DocumentIcon,
  Cash: RiCurrencyLine,
  Sales: HeadsetIcon,
  Status: PulseIcon,
  Support: LifebuoyIcon,

  // DataPipeline project types
  DP_amazon: AmazonIcon,
  DP_ebay: EbayIcon,
  DP_experimental: FunnelIcon,
  DP_google: GoogleIcon,
  DP_scraperapi: ScraperAPILogoNoText,
  DP_unknown: XIcon,
  DP_urls: RawUrlsIcon,
  DP_walmart: WalmartIcon2,
  DP_redfin: RedfinIcon,

  // programming languages
  Lang_curl: CurlIcon,
  Lang_java: RiJavaLine,
  Lang_nodejs: RiNodejsLine,
  Lang_php: RiPhpLine,
  Lang_python: PythonIcon,
  Lang_ruby: RubyIcon,

};

export type IconName = keyof typeof Icons;

export default Icons;
