import { isValidRedfinUrl } from "components/hostedScrapingValidators";
import { SDEContent } from "./sdecontentTypes";

export const RedfinAgent: SDEContent = {
  projectType: 'async_redfin_agent',
  projectCategory: 'redfin',
  apiUrlFn: (apiHost: string) => {
    return `${apiHost}/structured/redfin/agent`;
  },
  docsSection: 'redfin-agent-api',
  docsSectionTitle: 'Redfin Agent API',
  docsSectionAsync: 'redfin-agent-api-async',
  docsSectionAsyncTitle: 'Redfin Agent API (Async)',
  inputKey: 'url',
  inputsKey: 'urls',
  validatorFunction: isValidRedfinUrl,
  newProjectEditProject: {
    inputBoxLabels: {
      listLiteralInputTitle: 'URL',
      listLiteralInputPlaceholder: 'Enter Redfin agent URLs',
    },
    inputSectionLabels: {
      inputSectionTitle: "Input Redfin agent URLs",
      inputSectionDescription: "Enter the URLs of the Redfin agents to start scraping.",
    },
    projectContent: {
      title: 'Redfin Agent Details',
      description: 'Get all details about an agent or agent group like name, license number, selling statistics and contact details.',
      icon: 'redfin',
      inputTitle: 'Enter Redfin agent URLs',
      },
  },
  apiPlayground: {
    inputSectionLabels: {
      inputSectionTitle: 'Input Redfin agent URL',
      inputSectionDescription: 'Enter the URL of the Redfin agent to start scraping.',
    },
    projectContent: {
      title: 'Redfin - Agent Details',
      description: 'Get all details about an agent or agent group like name, license number, selling statistics and contact details.',
      icon: 'redfin',
    },
    enterInputsPlaceholder: 'https://www.redfin.com/real-estate-agents/david-tom',
    enterInputsMessage: 'Enter Redfin Agent URL',
    codeViewPlaceHolder: 'Please provide a valid agent url',
    dropdownTitle: 'Redfin - Agent Details'
  }
};
