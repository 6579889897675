import { useEffect } from "react";
import { useFetcher } from "react-router-dom";

import noChartDataIllustration from "assets/images/dashboard-no-chart-data-illustration.png";

import Button from "components/Button";
import Card from "components/Card";
import ProgressBar from "components/ProgressBar";
import { PureTooltip } from "components/Tooltip";
import Spinner from "components/Spinner";

import { SubscriptionType, UserType } from "providers/UserProvider";

import { Fetchers } from "routes/dataroutes/Fetchers";
import { useUser } from "routes/dataroutes/UserData";

import { fmtDate } from "utils";
import { dateDiff } from "utils/dateUtils";
import { DAY } from "utils/timeConstants";

import UsageHistoryCharts from "./UsageHistoryCharts";


const getRemainingDays = (user: UserType | null, subscription: SubscriptionType | undefined) => {
  if (user?.isFreeTrial) {
    const diff = dateDiff(new Date(user.signedUpAt!), Date.now(), DAY);
    return Math.max(0, 7 - diff);
  }

  return subscription ? dateDiff(Date.now(), subscription.next_renewal_at * 1000, DAY) : undefined;
};

const getTooltipContent = (user: UserType | null, subscription: SubscriptionType | undefined) => {
  if (user?.isFreeTrial) {
    return "Your trial ends on " + fmtDate(
      new Date(new Date(user.signedUpAt!).getTime() + (7 * DAY)), { weekday: undefined }
    ) + ".";
  }

  return subscription ? "Resets on " + fmtDate(new Date(subscription.next_renewal_at * 1000), { weekday: undefined }) + "." : undefined;
}

function PlanEndsInLabel() {
  const user = useUser();

  const subscriptionFetcher = useFetcher<{ activeSubscription: SubscriptionType } | undefined>();

  useEffect(() => {
    if ((subscriptionFetcher.state === "idle") && (subscriptionFetcher.data === undefined)) {
      subscriptionFetcher.load("/billing-data/subscription/details");
    }
  });

  const remainingDays = getRemainingDays(user, subscriptionFetcher.data?.activeSubscription);

  const tooltipContent = getTooltipContent(user, subscriptionFetcher.data?.activeSubscription);


  return (
    <PureTooltip content={ tooltipContent }>
      <div
        className="flex flex-row gap-x-1 items-center text-neutral-700 text-sm border-b-[1px] border-b-neutral-700 border-dotted cursor-default">
        { remainingDays === 0 && <span>Ends today</span> }
        { remainingDays !== 0 && (
          <>
            <span>Ends in</span>
            { remainingDays ?? <Spinner theme="secondary" size="XXS"/> }
            <span>{ (remainingDays || 2) > 1 ? "days" : "day" }</span>
          </>
        ) }
      </div>
    </PureTooltip>
  );
}

export interface CountersData {
  requestCount: number;
  concurrentRequests: number;
  totalCredits: number;
  concurrencyLimit: number;
}

function PlanUsageCard() {
  const user = useUser();

  // no need to do anything with this fetcher here, it's the usage charts component which will initiate the fetch
  // and which is also responsible for refreshing the data. here we just show what is fetched
  const chartsDataFetcher = useFetcher(Fetchers.CHARTS_DATA);


  return (
    <Card
      title={ user?.isFreeTrial ? "Trial" : (user?.planName + " plan") }
      extra={ <PlanEndsInLabel /> }
      size="LG"
      borderless
      footer={ <Button text="Upgrade to larger plan" className="button button-primary w-full" href="/billing" /> }
    >
      <div className="flex flex-col h-full py-4 gap-y-4">
        <ProgressBar
          label="API credits used"
          fullWidth
          current={ chartsDataFetcher.data?.counters?.requestCount }
          max={ chartsDataFetcher.data?.counters?.totalCredits || user?.apiCallLimit }
          loading={ chartsDataFetcher.state === "loading" }
        />
        <ProgressBar
          label="Concurrent threads"
          fullWidth
          current={ chartsDataFetcher.data?.counters?.concurrentRequests }
          max={ chartsDataFetcher.data?.counters?.concurrencyLimit || user?.concurrentRequestLimit }
          loading={ chartsDataFetcher.state === "loading" }
        />
      </div>
    </Card>
  );
}

function NoUsagePanel() {
  return (
    <div className="flex flex-col gap-y-5 items-center self-center justify-self-center max-w-[274px]">
      <img src={ noChartDataIllustration } alt="there is no data yet" />
      <div className="flex flex-col gap-y-1.5 items-center">
        <p className="font-medium">There is no data yet!</p>
        <p className="text-sm text-center">Let’s get started and make your first request to see your statistics.</p>
      </div>
    </div>
  );
}

function ChartsPanel() {
  const user = useUser();

  return user?.madeFirstRequest ?
    <UsageHistoryCharts/> :
    <NoUsagePanel/>;
}

export default function PlanOverviewAndStatsCard() {
  return (
    <div className="flex flex-col gap-y-4">
      <div className="font-semibold text-neutral-900">Your plan</div>
      <Card size="LG">
        <div className="flex flex-col _1320:grid _1320:grid-cols-[330px_minmax(0,1fr)] gap-x-8 gap-y-12 h-full w-full">
          <PlanUsageCard />
          <ChartsPanel />
        </div>
      </Card>
    </div>
  );
};
