import _ from "lodash";


export const cx = (...classes: any): string => {
  return classes.filter(Boolean).join(" ");
};

export const fmtDate = (date: Date, opts?: Intl.DateTimeFormatOptions) => {
  return new Intl.DateTimeFormat("en-US", {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric",
    ...opts
  }).format(date);
};

export function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

export const fmtCurrency = (amount?: number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0
  }).format(amount || 0);
};

export const fmtNumber = (amount?: number) => {
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 0
  }).format(amount || 0);
};

export const sleep = (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms));

export const join = (arr: Array<any>, separator: string, lastSeparator?: string) => {
  if (lastSeparator && (arr.length > 1)) {
    return `${arr.slice(0, -1).join(separator)}${lastSeparator}${_.last(arr)}`;
  } else {
    return `${arr.join(separator)}`;
  }
};

export const toNumber = (value: string | number | undefined) => {
  if (typeof value === "string") {
    return Number.parseInt(value, 10);
  } else {
    return value;
  }
};

export const snakeCaseToCamelCase = (data: any) => _.mapKeys(data, (_value, key) => _.camelCase(key));

export const removeNonMatchingProperties = (firstItem: any, secondItem: any) => {
  for (const key in firstItem) {
    if (!secondItem.hasOwnProperty(key)) {
      delete firstItem[key];  // Delete the property if it doesn't exist in the secondItem
    }
  }
}

export const convertStringsInObject = (obj: any) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      // Convert 'true' or 'false' strings to boolean
      if (typeof value === 'string' && (value === 'true' || value === 'false')) {
        obj[key] = value === 'true';
      }

      // Convert latitude and longitude to numbers
      if ((key === 'latitude' || key === 'longitude') && !isNaN(parseFloat(value))) {
        obj[key] = parseFloat(value);
      }
    }
  }
  return obj;
};