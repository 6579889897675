import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { CollectorType } from "providers/HostedScrapingProvider";

import { ApiCallConfig, ScrapingMethod, SupportedLanguages } from "routes/dashboard/ApiPlaygroundTypes";

import { useLocalStorage } from "./useLocalStorage";


export function toApiCallConfig(settings: ApiPlaygroundSettings): ApiCallConfig {
  return {
    input: settings.input,
    scrapingMethod: settings.scrapingMethod,
    config: {
      type: settings.type,
      apiParams: settings.apiParams
    }
  };
}

export type ApiPlaygroundLocationState = {
  language: SupportedLanguages;
  scrapingMethod: ScrapingMethod;
  collectorType: CollectorType;
  url: string;
};

export type ApiPlaygroundSettings = {
  input: string;
  setInput: Dispatch<SetStateAction<string>>;
  scrapingMethod: ScrapingMethod;
  setScrapingMethod: Dispatch<SetStateAction<ScrapingMethod>>;
  savedScrapingMethod: ScrapingMethod,
  setSavedScrapingMethod: Dispatch<SetStateAction<ScrapingMethod>>;
  type: CollectorType;
  setType: Dispatch<SetStateAction<CollectorType>>;
  sdeType: CollectorType;
  setSdeType: Dispatch<SetStateAction<CollectorType>>;
  apiParams: any;
  setApiParams: Dispatch<SetStateAction<any>>;
  language: SupportedLanguages;
  setLanguage: Dispatch<SetStateAction<SupportedLanguages>>;
}

export default function useApiPlaygroundSettings(): ApiPlaygroundSettings {
  const locationState = useLocation().state as ApiPlaygroundLocationState | undefined;

  const [ input, setInput ] = useState<string>(locationState?.url || "");

  const [ savedScrapingMethod, setSavedScrapingMethod ] = useLocalStorage<ScrapingMethod>("apiRequestScrapingMethod", "api");
  const [ scrapingMethod, setScrapingMethod ] = useState<ScrapingMethod>(locationState?.scrapingMethod || savedScrapingMethod);
  useEffect(() => {
    setSavedScrapingMethod(scrapingMethod);
  }, [ setSavedScrapingMethod, scrapingMethod ]);

  const [ sdeType, setSdeType ] = useLocalStorage<CollectorType>("apiPlaygroundScrapingType", "async_google_search");
  const [ type, setType ] = useState<CollectorType>(locationState?.collectorType || sdeType);

  useEffect(() => {
    if (scrapingMethod === "structured_data_endpoint") {
      if (type === "async_urls") {
        // SDEs must use their own types, pick the last selected SDE instead of using 'async_urls'
        setType(sdeType);
      } else {
        // all good, save the user's choice
        setSdeType(type);
      }
    } else {
      // not using SDEs, collector type must be 'async_urls'
      setType("async_urls");
    }
  }, [ type, sdeType, scrapingMethod, setType, setSdeType ]);

  const [ apiParams, setApiParams ] = useState<any>({});

  const [ savedLanguage, setSavedLanguage ] = useLocalStorage<SupportedLanguages>("apiRequestSelectedLanguage", "python");
  const [ language, setLanguage ] = useState<SupportedLanguages>(locationState?.language || savedLanguage);
  useEffect(() => {
    setSavedLanguage(language);
  }, [ setSavedLanguage, language ]);


  return {
    input,
    setInput,
    scrapingMethod,
    setScrapingMethod,
    savedScrapingMethod,
    setSavedScrapingMethod,
    type,
    setType,
    sdeType,
    setSdeType,
    apiParams,
    setApiParams,
    language,
    setLanguage,
  };
};
