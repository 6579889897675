import { ComponentType } from "react";

import { ReactComponent as CurlIcon } from "assets/icons/curl-icon.svg";
import { ReactComponent as NodeJSIcon } from "assets/icons/nodejs-icon.svg";
import { ReactComponent as PHPIcon } from "assets/icons/php-icon.svg";
import { ReactComponent as PythonIcon } from "assets/icons/python-icon.svg";

import Button from "components/Button";
import Card from "components/Card";
import ExtLink from "components/ExtLink";
import { IconName } from "components/Icons";

import { useSupportWidget } from "hooks/useSupportWidget";

import { RecentlyViewedPage } from "providers/RecentlyViewed/RecentlyViewedProvider";

import { useUser } from "routes/dataroutes/UserData";


const docLinks: IDocLinkProps[] = [
  {
    text: "View NodeJS documentation",
    href: "https://docs.scraperapi.com/v/nodejs/making-requests/",
    Icon: NodeJSIcon,
    recentlyViewedIconName: "Lang_nodejs",
    recentlyViewedTitle: "NodeJS documentation"
  },
  {
    text: "View Python documentation",
    href: "https://docs.scraperapi.com/v/python/making-requests/",
    Icon: PythonIcon,
    recentlyViewedIconName: "Lang_python",
    recentlyViewedTitle: "Python documentation"
  },
  {
    text: "View cURL documentation",
    href: "https://docs.scraperapi.com/making-requests/",
    Icon: CurlIcon,
    recentlyViewedIconName: "Lang_curl",
    recentlyViewedTitle: "cURL documentation"
  },
  {
    text: "View PHP documentation",
    href: "https://docs.scraperapi.com/v/php/making-requests/",
    Icon: PHPIcon,
    recentlyViewedIconName: "Lang_php",
    recentlyViewedTitle: "PHP documentation"
  }
];

interface IDocLinkProps {
  text: string;
  href: string;
  Icon: ComponentType<any>;
  recentlyViewedIconName: IconName;
  recentlyViewedTitle: string;
}

function DocLink(
  {
    text,
    href,
    Icon,
    recentlyViewedIconName,
    recentlyViewedTitle
  }: IDocLinkProps
) {
  return (
    <RecentlyViewedPage
      iconName={ recentlyViewedIconName }
    >
      <ExtLink
        to={ href }
        tracked
        trackedTitle={ recentlyViewedTitle }
      >
        <div className="flex flex-row gap-x-3 items-center">
          <Icon className="w-11 h-11 text-neutral-500 bg-neutral-50 p-1.5 rounded border-neutral-50" />
          <div className="text-sm">{ text }</div>
        </div>
      </ExtLink>
    </RecentlyViewedPage>
  );
}

export default function NeedHelpCard(
  {
    className,
  }: {
    className?: string;
  }
) {
  const supportWidget = useSupportWidget();
  const user = useUser();

  return (
    <Card
      title="Need help?"
      className={ className }
    >
      <div className="flex flex-col gap-y-5">
        <div className="flex flex-col gap-y-3">
          { docLinks.map((link, idx) => <DocLink key={ idx } { ...link } /> ) }
        </div>
        <div className="flex flex-row items-start gap-x-2">
          <RecentlyViewedPage
            iconName="Documentation"
          >
            <Button
              text="View all docs"
              className="button button-secondary"
              size="SM"
              href="https://docs.scraperapi.com/documentation-overview"
              tracked
              trackedTitle="Documentation overview"
            />
          </RecentlyViewedPage>
          <Button
            text="Contact support"
            className="button button-tertiary"
            size="SM"
            onClick={ () => supportWidget.showSupportForm(user?.email) }
          />
        </div>
      </div>
    </Card>
  );
};
