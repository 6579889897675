export const async_redfin_forsale = {
  input: "https://redfin.com/NH/Salem/1-Chestnut-Dr-03079/home/96531263",
  result: "{\n" +
    "  \"url\": \"https://redfin.com/NH/Salem/1-Chestnut-Dr-03079/home/96531263\",\n" +
    "  \"type\": \"for_sale\",\n" +
    "  \"image_urls\": [\n" +
    "    \"https://ssl.cdn-redfin.com/photo/154/bigphoto/196/5023196_0.jpg\",\n" +
    "    \"https://ssl.cdn-redfin.com/photo/154/bigphoto/196/5023196_1_0.jpg\",\n" +
    "    ...\n" +
    "  ],\n" +
    "  \"price\": 899900,\n" +
    "  \"currency\": \"$\",\n" +
    "  \"sq_ft\": 2928,\n" +
    "  \"price_per_sq_ft\": 307,\n" +
    "  \"latitude\": 42.755854,\n" +
    "  \"longitude\": -71.2134495,\n" +
    "  \"beds\": 4,\n" +
    "  \"baths\": 2.5,\n" +
    "  \"url_map\": \"https://maps.google.com/maps/api/staticmap?sensor=false&style=feature%3Aadministrative.land_parcel%7Cvisibility%3Aoff&style=feature%3Alandscape.man_made%7Cvisibility%3Aoff&style=feature%3Atransit.station%7Chue%3A0xffa200&center=42.755854%2C-71.2134495&channel=desktop_xdp_above_fold_static_preview&size=200x200&scale=1&format=jpg&zoom=11&client=gme-redfin&signature=UOuXHmn2QMUheU2YnGaziirBk9w=\",\n" +
    "  \"description\": \"Welcome to this pristine four-bedroom Colonial situated in a desirable cul-de-sac neighborhood. Thoughtfully cared for and updated this home offers a functional and welcoming layout featuring spacious rooms, hardwood floors throughout and plenty of natural light. The chef&rsquo;s kitchen is the heart of the home with high-end stainless steel appliances, a 5 burner gas cooktop, custom cabinetry and a large quartz-topped island perfect for meal prep or casual dining. The main level also includes a comfortable living room a formal dining room for hosting and a cozy family room with a gas fireplace that&rsquo;s perfect for gathering. Upstairs the primary suite offers a peaceful retreat with a private en-suite bath and walk-in closet. Three additional bedrooms provide flexibility for family, guests or a home office. Outside the backyard is ideal for relaxing or entertaining with a well-maintained lawn and a deck that&rsquo;s r\n" +
    "  \"address\": \"1 Chestnut Dr, Salem, NH 03079\",\n" +
    "  \"active\": true,\n" +
    "  \"agents\": [\n" +
    "    {\n" +
    "      \"name\": \"Caroleann M. Ohannessian\",\n" +
    "      \"broker_name\": \"RE/MAX Innovative Properties\"\n" +
    "    }\n" +
    "  ],\n" +
    "  \"amenities\": {\n" +
    "    \"stories\": \"2\",\n" +
    "    \"community\": \"Salem\",\n" +
    "    \"county\": \"Rockingham\",\n" +
    "    \"mls\": \"5023196\",\n" +
    "    \"built\": \"Built in 2002\",\n" +
    "    \"lot_size\": \"0.58 acres\",\n" +
    "    \"property_type\": \"Single-family\",\n" +
    "    \"heating_cooling\": \"Has A/C\",\n" +
    "    \"parking\": \"2 garage spaces\"\n" +
    "  },\n" +
    "  \"year_built\": 2002,\n" +
    "  \"property_type\": \"Single Family Residential\",\n" +
    "  \"schools\": [\n" +
    "    {\n" +
    "      \"name\": \"Dr. L. F. Soule School\",\n" +
    "      \"address\": \"173 S Policy St, Salem, NH 03079\",\n" +
    "      \"url\": \"http://redfin.com/school/135289/NH/Salem/Dr-L-F-Soule-School\",\n" +
    "      \"review_count\": 3,\n" +
    "      \"great_schools_rating\": 4,\n" +
    "      \"great_school_url\": \"https://www.greatschools.org/new-hampshire/salem/434-Dr.-L.-F.-Soule-School/\",\n" +
    "      \"parent_rating\": 4,\n" +
    "      \"distance_in_miles\": \"0.8\",\n" +
    "      \"grade_ranges\": \"K-5\",\n" +
    "      \"institution_type\": \"Public\",\n" +
    "      \"number_of_students\": 249,\n" +
    "      \"student_to_teacher_ratio\": 11\n" +
    "    },\n" +
    "    {\n" +
    "      \"name\": \"Woodbury School\",\n" +
    "      \"address\": \"206 Main St, Salem, NH 03079\",\n" +
    "      \"url\": \"http://redfin.com/school/136522/NH/Salem/Woodbury-School\",\n" +
    "      \"review_count\": 3,\n" +
    "      \"great_schools_rating\": 5,\n" +
    "      \"great_school_url\": \"https://www.greatschools.org/new-hampshire/salem/439-Woodbury-School/\",\n" +
    "      \"parent_rating\": 2,\n" +
    "      \"distance_in_miles\": \"1.9\",\n" +
    "      \"grade_ranges\": \"6-8\",\n" +
    "      \"institution_type\": \"Public\",\n" +
    "      \"number_of_students\": 799,\n" +
    "      \"student_to_teacher_ratio\": 14\n" +
    "    },\n" +
    "    {\n" +
    "      \"name\": \"Salem High School\",\n" +
    "      \"address\": \"44 Geremonty Dr, Salem, NH 03079\",\n" +
    "      \"url\": \"http://redfin.com/school/135508/NH/Salem/Salem-High-School\",\n" +
    "      \"review_count\": 2,\n" +
    "      \"great_schools_rating\": 5,\n" +
    "      \"great_school_url\": \"https://www.greatschools.org/new-hampshire/salem/438-Salem-High-School/\",\n" +
    "      \"parent_rating\": 5,\n" +
    "      \"distance_in_miles\": \"1.7\",\n" +
    "      \"grade_ranges\": \"9-12\",\n" +
    "      \"institution_type\": \"Public\",\n" +
    "      \"number_of_students\": 1131,\n" +
    "      \"student_to_teacher_ratio\": 11\n" +
    "    }\n" +
    "  ],\n" +
    "  \"similar_homes\": [\n" +
    "    {\n" +
    "      \"remarks\": \"Discover unparalleled luxury in this exquisite 4-bedroom, 3.5-bath estate overlooking Campbell's Scottish Highlands Golf Course. This masterpiece blends contemporary sophistication with timeless elegance. Enter the stately foyer into a world of refinement. To your right, an elegant dining room with wainscoting and tray ceiling awaits. Across, find a well-appointed office with tiled floor and an expansive window. The great room impresses with the first of two fireplaces, rich hardwood floors, and soaring vaulted ceilings, perfect for entertaining. From there, you head to the four seasons room which boasts the second stone gas fireplace and scenic views. Indulge your culinary passions in the c\",\n" +
    "      \"url\": \"http://redfin.com/NH/Salem/43-Ticklefancy-Ln-03079/home/60349931\",\n" +
    "      \"price\": 1275000,\n" +
    "      \"sq_ft\": 5188,\n" +
    "      \"beds\": 4,\n" +
    "      \"baths\": 3.5,\n" +
    "      \"stories\": 1\n" +
    "    }\n" +
    "  ],\n" +
    "  \"walk_score\": 62,\n" +
    "  \"bike_score\": 37,\n" +
    "  \"nearby_places\": [\n" +
    "    {\n" +
    "      \"name\": \"99 Restaurants\",\n" +
    "      \"address\": \"149 S Broadway, Salem, NH, 03079, us\",\n" +
    "      \"latitude\": 42.76888656616211,\n" +
    "      \"longitude\": -71.21973419189453,\n" +
    "      \"popularity\": 0.5199999809265137,\n" +
    "      \"phone_number\": \"(603) 893-5596\",\n" +
    "      \"website\": \"http://www.99restaurants.com/\",\n" +
    "      \"categories\": [\n" +
    "        \"Bar\"\n" +
    "      ],\n" +
    "      \"distance\": 0.9563021208779819\n" +
    "    },\n" +
    "    {\n" +
    "      \"name\": \"Peppers Pub\",\n" +
    "      \"address\": \"326 S Broadway, Salem, NH, 03079, us\",\n" +
    "      \"latitude\": 42.75418472290039,\n" +
    "      \"longitude\": -71.20906066894531,\n" +
    "      \"popularity\": 0.10000000149011612,\n" +
    "      \"categories\": [\n" +
    "        \"Bar\",\n" +
    "        \"Sports Bar\",\n" +
    "        \"Burger Joint\",\n" +
    "        \"Sandwich Restaurant\"\n" +
    "      ],\n" +
    "      \"distance\": 0.25103686646367174\n" +
    "    },\n" +
    "    ...\n" +
    "  ],\n" +
    "  \"transit\": [\n" +
    "    {\n" +
    "      \"stop_name\": \"Village Plaza -  Jo-Anns Fabrics\",\n" +
    "      \"latitude\": 42.736726540051,\n" +
    "      \"longitude\": -71.196858494474,\n" +
    "      \"routes\": [\n" +
    "        {\n" +
    "          \"short_name\": \"10\",\n" +
    "          \"long_name\": \"VILLAGE MALL VIA BROADWAY\"\n" +
    "        },\n" +
    "        {\n" +
    "          \"short_name\": \"10\",\n" +
    "          \"long_name\": \"Methuen Square\"\n" +
    "        },\n" +
    "        {\n" +
    "          \"short_name\": \"40\",\n" +
    "          \"long_name\": \"Methuen Square\"\n" +
    "        }\n" +
    "      ]\n" +
    "    },\n" +
    "    {\n" +
    "      \"stop_name\": \"Pelham Street Park & Ride\",\n" +
    "      \"latitude\": 42.727395,\n" +
    "      \"longitude\": -71.200232,\n" +
    "      \"routes\": [\n" +
    "        {\n" +
    "          \"short_name\": \"99\",\n" +
    "          \"long_name\": \"Methuen-Lawrence-Andover to Boston\"\n" +
    "        }\n" +
    "      ]\n" +
    "    },\n" +
    "    ...\n" +
    "  ],\n" +
    "  \"redfin_agent\": \"Sherre Dubis\",\n" +
    "  \"redfin_agent_average_rating\": 10,\n" +
    "  \"redfin_agent_url\": \"http://redfin.com/real-estate-agents/sherre-dubis\",\n" +
    "  \"redfin_agent_phone_number\": \"603-968-5261\",\n" +
    "  \"redfin_agent_deal_count\": 232,\n" +
    "  \"redfin_agent_volume\": 92046099,\n" +
    "  \"redfin_agent_highest_deal\": 1300000,\n" +
    "  \"url_map_2_x\": \"https://maps.google.com/maps/api/staticmap?sensor=false&style=feature%3Aadministrative.land_parcel%7Cvisibility%3Aoff&style=feature%3Alandscape.man_made%7Cvisibility%3Aoff&style=feature%3Atransit.station%7Chue%3A0xffa200&center=42.755854%2C-71.2134495&channel=ldp-minimap&path=color%3A0x2E7E36AA%7Cweight%3A1%7Cfillcolor%3A0x2E7E3633%7C42.7556517661979%2C-71.2136135592431%7C42.755652853304%2C-71.2136293929133%7C42.7556559108632%2C-71.2136447455755%7C42.7556608497788%2C-71.2136591683348%7C42.7556675261445%2C-71.2136722383488%7C42.7556757440131%2C-71.2136835759095%7C42.7556852653219%2C-71.2136928504789%7C42.755701061368%2C-71.2137021953775%7C42.7557599003748%2C-71.213722775569%7C42.7558199448114%2C-71.213735406652%7C42.7558806135693%2C-71.2137399682356%7C42.7559413186263%2C-71.2137364132741%7C42.7560014722875%2C-71.2137247777876%7C42.7561640281287%2C-71.2136878321098%7C42.7561751049447%2C-71.2136832168033%7C42.7561854509485%2\n" +
    "}\n"
};
