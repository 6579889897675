import { useState } from "react";

import { RiEyeOffLine, RiEyeLine } from "@remixicon/react"

import InputFieldWithValidation, { IInputFieldWithValidationProps } from "components/InputFieldWithValidation";


function ShowPasswordIcon(
  {
    showPassword,
    onClick
  }: {
    showPassword: boolean,
    onClick: () => void
  }
) {
  const iconProps = {
    className: "w-5 h-5 cursor-pointer text-neutral-500",
    onClick: onClick
  }
  return showPassword ?
    <RiEyeLine { ...iconProps } /> :
    <RiEyeOffLine { ...iconProps } />;
}

interface IPasswordInputProps extends Pick<IInputFieldWithValidationProps, "label" | "value" | "onChange" | "pattern" | "isValidated" | "autoFocus" | "name"> {
}

export default function PasswordInput(
  {
    label = "Password",
    value,
    onChange,
    pattern,
    isValidated,
    autoFocus,
    name = "password"
  }: IPasswordInputProps
) {
  const [ showPassword, setShowPassword ] = useState(false);


  return <InputFieldWithValidation
    name={ name }
    label={ label }
    value={ value }
    onChange={ onChange }
    type={ showPassword ? "text" : "password" }
    placeholder="Please enter your password"
    required
    minLength={ 5 }
    tooShortErrorMessage="Your password has to be at least 5 characters long."
    pattern={ pattern }
    patternMismatchErrorMessage="Passwords do not match"
    isValidated={ isValidated }
    autoFocus={ autoFocus }
    TrailingElement={ <ShowPasswordIcon showPassword={ showPassword } onClick={() => setShowPassword(oldState => !oldState)} /> }
  />;
};
