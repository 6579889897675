import { MenuItem } from "@headlessui/react";

import * as UserMenuItems from "./UserMenuItems";

import Menu, { MenuButton, MenuItems } from "components/Menu";
import RotatingArrowIcon from "components/RotatingArrowIcon";

import { useNewDashboardDesign } from "providers/NewDashboardDesignProvider";

import { useUser } from "routes/dataroutes/UserData";

import { getUserInitials, getUsername } from "utils/userUtils";
import { cx } from "utils";
import { MutableRefObject } from "react";


export function UserBadge({ initial }: { initial: string }) {
  return (
    <div className="flex-shrink-0 text-center place-content-center justify-center w-8 h-8 bg-gray dark:bg-neutral-600 rounded-full">
      <span className="text-white font-semibold text-sm">{ initial }</span>
    </div>
  );
}

function UsernameAndEmail({ username, email }: { username: string, email: string }) {
  return (
    <div className="flex flex-col space-y-1 items-start">
      <span className="text-base font-medium text-gray dark:text-neutral-600 leading-none max-w-[160px] truncate">{ username }</span>
      <span className="text-xs text-gray dark:text-neutral-500 leading-none max-w-[160px] truncate">{ email }</span>
    </div>
  );
}

type MenuItemList = {
  items?: MenuItemList[];
  disabled?: boolean;
  menuItem?: (close?: CloseFn) => JSX.Element;
};

type CloseFn = () => void;

function RenderMenuItems({ menuItems, indexPrefix }: { menuItems: MenuItemList[], indexPrefix?: string }) {
  return (
    <>
      { menuItems.map((item, index) => {
        const itemIndex = indexPrefix ? indexPrefix + "-" + index : index.toString(10);

        if (item.items) {
          return (
            <div className="py-3" key={ itemIndex }>
              <RenderMenuItems menuItems={ item.items } indexPrefix={ itemIndex }/>
            </div>
          );
        }

        if (item.menuItem) {
          return (
            <MenuItem
              key={ itemIndex }
              disabled={ item.disabled }
            >
              { ({ close }) => (
                <>
                  { item.menuItem?.(close) }
                </>
              ) }
            </MenuItem>
          );
        }

        return <></>;
      }) }
    </>
  );
}

function UserMenuButton(
  {
    collapsed
  }: {
    collapsed: boolean;
  }
) {
  const user = useUser()!;

  return (
    <MenuButton className="p-4 hover:bg-slate-50 dark:hover:bg-neutral-100 flex items-center space-x-2 w-full">
      { ({ open }) => (
        <>
          <UserBadge initial={ getUserInitials(user) }/>
          { !collapsed && (
            <div className="flex flex-row items-center justify-between w-full gap-x-1.5" data-user-email={ user.email }>
              <UsernameAndEmail username={ getUsername(user) } email={ user.email } />
              <RotatingArrowIcon direction="up" rotated={ open } className="size-4 text-neutral-500" />
            </div>
          ) }
        </>
      ) }
    </MenuButton>
  );
}

function UserMenuMenuItems(
  {
    width,
    refUserMenu
  }: {
    width?: string;
    refUserMenu: MutableRefObject<HTMLDivElement | null>;
  }
) {
  const user = useUser();
  const { newDashboardDesignSetting, newDesignForced } = useNewDashboardDesign();

  const menuItems: MenuItemList[] = [
    {
      items: [
        {
          disabled: true,
          menuItem: () => <UserMenuItems.AccountEmail />
        },
        {
          disabled: true,
          menuItem: () => <UserMenuItems.AccountApiKey />
        }
      ]
    },
    {
      menuItem: (close?: CloseFn) => <UserMenuItems.ChangePassword onClick={ close } />
    },
    {
      disabled: user?.isBlocked,
      menuItem: (close?: CloseFn) => <UserMenuItems.ManageApiKeys onClick={ close } />
    }
  ];

  if (!newDesignForced && (newDashboardDesignSetting === "yes")) {
    menuItems.push({
      menuItem: (close?: CloseFn) => <UserMenuItems.UseOldDashboardDesign onClick={ close } />
    });
  }

  menuItems.push({
    disabled: true,
    menuItem: (close?: CloseFn) => <UserMenuItems.Logout onClick={ close } refUserMenu={refUserMenu}/>
  });

  return (
    <MenuItems
      anchor={ { to: "top start", padding: "1rem", gap: "1rem" } }
      className={ cx("origin-bottom-left z-20", width ?? "w-72") }
      divided
    >
      <RenderMenuItems menuItems={ menuItems }/>
    </MenuItems>
  );
}

/**
 * Should never be used before login.
 */
export default function UserMenu(
  {
    collapsed,
    dropdownWidth,
    refUserMenu
  }: {
    collapsed: boolean;
    dropdownWidth?: string;
    refUserMenu: MutableRefObject<HTMLDivElement | null>;
  }
) {

  return (
    <Menu>
      <UserMenuButton collapsed={ collapsed } />
      <UserMenuMenuItems  width={ dropdownWidth } refUserMenu={refUserMenu}/>
    </Menu>
  );
}
