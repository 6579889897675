import _ from "lodash";
import { ApiCallConfig } from "routes/dashboard/ApiPlaygroundTypes";

const GTAG_ID = 'G-H7B6YKSKMX';

let gtagInitialzed = false;
const ensureGtagInitialized = () => {
  if (gtagInitialzed) {
    return;
  }

  if ((window as any).dataLayer === undefined) {
    console.warn("window.dataLayer is missing. Can't init gtag");
    return;
  }

  window.gtag = function() { (window as any).dataLayer.push(arguments); }

  console.log('Initializing gtag');
  window.gtag('js', new Date());
  window.gtag('config', GTAG_ID);

  gtagInitialzed = true;
}

const ga4Tracking = (eventName: string, params: any) => {
  try {
    ensureGtagInitialized();
    if (window?.gtag) {
      window?.gtag("event", eventName, {event_category: "engagement",...params});
    }
  } catch (e) {
    console.error(e);
  }
};

type CopyMethod = 'ctrlC' | 'copyButton' | 'downloadButton';

export const trackApiPlaygroundDataCopied = (copyMethod: CopyMethod, config: ApiCallConfig, credits: number | undefined) => {
  ga4Tracking('api_playground_data_copied', {copy_method: copyMethod, url: config.input, scrapingMethod: config.scrapingMethod, credits, config: config.config});
};

export const trackApiPlaygroundURLEntered = (url: string | undefined) => {
  ga4Tracking('api_playground_url_entered', {url});
};

export const trackApiPlaygroundParameterChanged = (parameter: string, newValue: unknown) => {
  ga4Tracking('api_playground_parameter_changed', {parameter, new_value: newValue});
};

export const trackApiPlaygroundLanguageSelectorClicked = (selectedLanguage: string | undefined) => {
  ga4Tracking('api_playground_language_selector_clicked', {selected_language: selectedLanguage});
};

export const trackApiPlaygroundCopyToClipboardClicked = () => {
  ga4Tracking('api_playground_copy_to_clipboard_clicked', {});
};

export const trackApiPlaygroundSdeSelected = (selectedSDE: string) => {
  ga4Tracking('api_playground_sde_selected', {selected_sde: selectedSDE});
};

export const trackNewDesignBannerOptionClicked = (option: string) => {
    ga4Tracking("new_design_banner_option_" + _.snakeCase(option), { option });
};

export const trackBackToOldDesignOptionClicked = (option: string) => ga4Tracking('back_to_old_design', { option });

export const trackOnboardingEvent = (event?: string) => {
  if (event) {
    ga4Tracking(event, {});
  }
};
