import { ReactNode } from "react";

import TaggedText from "components/TaggedText";

import { IssueSeverity } from "providers/IssuesProvider";

import { cx } from "utils";
import ExtLink from "components/ExtLink";


const severityToBackgroundColor = (severity: IssueSeverity): string => {
  switch (severity) {
    case 'blocking':
    case 'failure':
    case 'warning':
      return 'bg-orange-500 dark:bg-warning-500';
    case 'info':
      return 'bg-brandLightest-75 dark:bg-primary-50';
  }
}

interface IBannerProps {
  title: string | ReactNode;
  dismissible: boolean;
  severity: IssueSeverity;
  url?: string | undefined;
}

export function Banner({title, severity = 'info', url}: IBannerProps) {
  const bgColor = severityToBackgroundColor(severity);
  return (<div className={cx("flex bg-brandLightest-75 dark:bg-primary-50 text-sm text-center p-2.5", bgColor)}>
    <ExtLink disabled={!url} to={url ? url : '#'} className="w-full">
      { typeof title === "string" && (
        <TaggedText
          message={title}
          className="text-sm text-brandDarkest-650 dark:text-primary-800"
          linkStyle="font-bold hover:text-brandDarkest-800 dark:hover:text-primary-900"
        />
      ) }
      { typeof title !== "string" && (
        <>{ title }</>
      ) }
    </ExtLink>
    {/*

    It's disabled for 2 reasons
    1. The XIcon is misaligned
    2. It's not working. It has no mechanism to know which issue was dismissed
       We could store the id and the happened_at if we wanted to
       + we still couldn't dismiss the out of credit issues

    { dismissible && (
      <span><XIcon className="text-gray dark:text-neutral-600"/></span>
    )} */}
  </div>);
}
