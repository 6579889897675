import { useEffect } from "react";

interface ApiParams {
  countryCode?: string;
  tld?: string;
  [key: string]: any;
}

interface CollectorConfig {
  apiParams?: ApiParams;
  setApiParams?: (params: ApiParams) => void;
}

interface Option {
  text: string | undefined;
  value: string | undefined;
}

export const useFilteredApiParams = (
  collectorConfig: CollectorConfig | null,
  countryCodesToShow: Option[] | undefined,
  domainsToShow: Option[]
) => {
  // used on load.
  useEffect(() => {
    if (!collectorConfig?.apiParams || !collectorConfig?.setApiParams) return;

    const { countryCode, tld, ...restApiParams } = collectorConfig.apiParams;

    let ccExists = undefined;
    if(countryCodesToShow) {
        ccExists = countryCodesToShow.some((cc) => cc.text === countryCode);
    }

    const tldExists = domainsToShow.some((cc) => cc.text === tld);

    const updatedApiParams: ApiParams = {
      ...restApiParams,
      ...(ccExists && { countryCode }),
      ...(tldExists && { tld }),
    };

    collectorConfig.setApiParams(updatedApiParams);
    // eslint-disable-next-line
  }, []);
};
