import { fmtNumber } from "utils";
import { isUsingChargebeeCustomFields } from "utils/planUtils";

import { CustomPlanOverrideType } from "components/PricingPlan";
import CheckedText from "components/CheckedComponent";


const credits = (amount: number) => {
  return <CheckedText><b>{fmtNumber(amount)}</b> API Credits</CheckedText>;
};

const threads = (amount: number) => {
  return <CheckedText><b>{fmtNumber(amount)}</b> Concurrent Threads</CheckedText>;
};

const geo = (geotargeting: string) => {
  return <CheckedText>{geotargeting}</CheckedText>;
};

export const PlanAttributes: { [index: string]: { credits: number, threads: number, geo: string }; } = {
  student_npo: {
    credits: 50000,
    threads: 25,
    geo: "All Geotargeting"
  },

  affiliate: {
    credits: 50000,
    threads: 20,
    geo: "US & EU Geotargeting"
  },

  annual_affiliate: {
    credits: 50000,
    threads: 20,
    geo: "US & EU Geotargeting"
  },

  hobby_1: {
    credits: 250000,
    threads: 20,
    geo: "US & EU Geotargeting"
  },

  hobby_2: {
    credits: 100000,
    threads: 20,
    geo: "US & EU Geotargeting"
  },

  annual_hobby_2: {
    credits: 100000,
    threads: 20,
    geo: "US & EU Geotargeting"
  },

  startup: {
    credits: 1000000,
    threads: 50,
    geo: "US & EU Geotargeting"
  },

  startup_2: {
    credits: 1000000,
    threads: 50,
    geo: "US & EU Geotargeting"
  },

  annual_startup_2: {
    credits: 1000000,
    threads: 50,
    geo: "US & EU Geotargeting"
  },

  business: {
    credits: 3000000,
    threads: 100,
    geo: "All Geotargeting"
  },

  business_2: {
    credits: 3000000,
    threads: 100,
    geo: "All Geotargeting"
  },

  annual_business_2: {
    credits: 3000000,
    threads: 100,
    geo: "All Geotargeting"
  },

  professional_1: {
    credits: 14000000,
    threads: 400,
    geo: "All Geotargeting"
  },

  annual_professional_1: {
    credits: 14000000,
    threads: 400,
    geo: "All Geotargeting"
  },

  professional_202503: {
    credits: 5000000,
    threads: 200,
    geo: "All Geotargeting"
  },

  annual_professional_202503: {
    credits: 5000000,
    threads: 200,
    geo: "All Geotargeting"
  }
};

export default function PlanFeatures(
  planId?: string,
  planOverrides?: CustomPlanOverrideType,
  maxCreditsOfAvailablePlans?: number
) {
  const planAttributes = planId ? PlanAttributes[planId] : undefined;
  if (planAttributes) {
    return [
      credits(planAttributes.credits),
      threads(planAttributes.threads),
      geo(planAttributes.geo)
    ];
  } else if (isUsingChargebeeCustomFields(planId)) {
    if (planOverrides) {
      return [
        credits(planOverrides.cf_api_request_limit || 0),
        threads(planOverrides.cf_api_concurrency_limit || 0),
        planOverrides.cf_api_geotargeting === "Disabled" ? null : geo("All Geotargeting")
      ];
    } else {
      return [
        <span className="mx-2 text-justify">Need more than {fmtNumber(maxCreditsOfAvailablePlans ?? 3000000)} API Credits with all premium features and dedicated support?</span>
      ];
    }
  }
};
