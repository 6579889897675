import { useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Listbox } from "@headlessui/react";
import "react-datepicker/dist/react-datepicker.css";

import { ProjectDetailsHead } from 'components/hosted-scraping/ProjectDetailsHead';
import {
  isAmazonProject, isGoogleProject, isAsyncUrlsProject, isWalmartProject, isEbayProject, isRedfinProject,
  getInputSectionLabelsForAP, enterInputsMessageAP, enterInputsPlaceholderAP, codeViewPlaceHolderAP,
  sdeDescriptors,
  getSDEDescriptorForProjectType,
  getSDEPropertiesSchema
} from "sdecontent";
import { ProjectSummarySidebar } from "components/hosted-scraping/project-summary/ProjectSummarySidebar";
import Button from 'components/Button';
import { InputComments } from 'components/hosted-scraping/edit-project-components/InputComments';
import { UrlProjectAsyncApiParams, AmazonProjectAsyncApiParams, GoogleProjectAsyncApiParams, WalmartProjectAsyncApiParams, EbayProjectAsyncApiParams, RedfinProjectAsyncApiParams } from 'components/hosted-scraping/edit-project-components/editApiParams';
import { useLocalStorage } from "hooks/useLocalStorage";
import { ConfigProblem, ConfigProblemWithMessage, validateInput } from "components/hostedScrapingValidators";
import { SectionTitle } from "components/hosted-scraping/edit-project-components/SectionTitle";
import { Separator } from "components/hosted-scraping/edit-project-components/Separator";
import { ScrapingMethod, SupportedLanguages } from "./ApiPlaygroundTypes";
import { SectionRightHandSide } from "components/hosted-scraping/edit-project-components/SectionRightHandSide";
import { Section } from "components/hosted-scraping/edit-project-components/Section";
import { sampleCode } from "components/hosted-scraping/apiPlaygroundTemplates";
import Alert from "components/Alert";

import Toaster from "components/Toaster";
import { ListboxButton, ListboxElement, OptionsTransition} from "components/Listbox";
import { FullWidthLoadingSpinner } from "components/FullWidthLoadingSpinner";
import { ReactComponent as CopyIcon } from "assets/icons/copy-icon.svg";
import { PlayIcon, ShareIcon } from "@heroicons/react/outline";
import { useDebouncer } from "hooks/useDebouncer";
import scraperApi from "api";
import { CodeView } from "components/CodeView";
import { saveAs } from "file-saver";
import axios from "axios";
import { convertStringsInObject, cx, removeNonMatchingProperties, snakeCaseToCamelCase } from "utils";
import { useUser } from "routes/dataroutes/UserData";
import { AsyncApiParams, CollectorType, CostCalculationProject } from "providers/HostedScrapingProvider/types";
import { trackApiPlaygroundCopyToClipboardClicked, trackApiPlaygroundDataCopied, trackApiPlaygroundLanguageSelectorClicked, trackApiPlaygroundParameterChanged, trackApiPlaygroundSdeSelected, trackApiPlaygroundURLEntered } from "utils/Tracking";
import { RadioGroup, RadioGroupElement } from "components/RadioGroup";
import Spinner from "components/Spinner";
import { AdditionalOptionsTextBox } from "components/hosted-scraping/edit-project-components/AdditionalOptionsTextBox";
import { ErrorProblemsBox } from "components/hosted-scraping/edit-project-components/ErrorProblemsBox";
import BorderedPage from "components/BorderedPage";
import BorderLayout from "layouts/BorderLayout";
import { pickOption } from "components/hosted-scraping/edit-project-components/AdditionalOptionsListBox";
import useApiPlaygroundSettings, { ApiPlaygroundSettings, toApiCallConfig } from "hooks/useApiPlaygroundSettings";
import ScrollToHeaderTitle from "misc/ScrollToHeaderTitle";
import { getSDEParamsForAsyncType } from "scraperapi-sde-descriptor-lib";
import { shareOnMobile } from "react-mobile-share";
import useIsMobile from "hooks/useIsMobile";
import { PureTooltip } from "components/Tooltip";
import ApiPlaygroundComboBox from "./components/ApiPlaygroundComboBox";

const supportedLanguages: { value: SupportedLanguages, text: string }[] = [
  { value: 'curl', text: 'cURL' },
  { value: 'python', text: 'Python' },
  { value: 'nodejs', text: 'NodeJS' },
  { value: 'php', text: 'PHP' },
  { value: 'ruby', text: 'Ruby' },
  { value: 'java', text: 'Java' },
];

const sdeCollectorTypes: { value: CollectorType, text: string }[] =
  sdeDescriptors.map(sde => ({ value: sde.projectType, text: sde.apiPlayground.dropdownTitle }));

interface TryItResult {
  status: number;
  statusText: string;
  responseText: string;
}

const isJson = (text: string): boolean => {
  try {
    JSON.parse(text);
    return true;
  } catch {
    return false;
  }
}

const tryToFormatJSON = (originalText: string): string => {
  try {
    const obj = JSON.parse(originalText);
    return JSON.stringify(obj, null, 2);
  } catch {
    return originalText;
  }
}

const enterInputsMessage = (scrapingMethod: ScrapingMethod, collectorType: CollectorType | undefined) => {
  if (scrapingMethod === 'async') {
    return 'Enter URL addresses for scraping';
  } else if (scrapingMethod === 'proxy_mode' || scrapingMethod === 'api') {
    return 'Enter URL address for scraping';
  } else if (scrapingMethod === 'structured_data_endpoint') {
    return enterInputsMessageAP(collectorType);
  } else {
    return '';
  }
}

const enterInputsPlaceholder = (scrapingMethod: ScrapingMethod, collectorType: CollectorType | undefined) => {
  if (scrapingMethod === 'async' || scrapingMethod === 'proxy_mode' || scrapingMethod === 'api') {
    return 'https://httpbin.org/json';
  } else if (scrapingMethod === 'structured_data_endpoint') {
    return enterInputsPlaceholderAP(collectorType);
  } else {
    return '';
  }
}

const codeViewPlaceholderMessage = (scrapingMethod: ScrapingMethod, collectorType: CollectorType | undefined) => {
  if (scrapingMethod === 'async' || scrapingMethod === 'proxy_mode' || scrapingMethod === 'api') {
    return 'Please provide a valid URL';
  } else if (scrapingMethod === 'structured_data_endpoint') {
    return codeViewPlaceHolderAP(collectorType);
  } else {
    return '';
  }
}

export const ApikeyBox = ({ apiKey }: { apiKey: string }) => {
  const [recentlyCopied, setRecentlyCopied] = useState(false);
  let timerHandle = useRef<number>();

  useEffect(() => {
    timerHandle.current = window.setTimeout(
      () => setRecentlyCopied(false),
      3000
    );

    // Clear the timer if we unmount before completion
    return () => clearTimeout(timerHandle.current);
  }, [recentlyCopied]);

  const copyApiKeyToClipboard = () => {
    if (recentlyCopied) {
      return;
    }
    navigator.clipboard.writeText(apiKey)
      .then(() => {
        Toaster.success('API key copied to clipboard');
        setRecentlyCopied(true);
      })
      .catch((error) => {
        console.error('Error copying to clipboard', error);
        Toaster.error('API key copy failed');
      });
  };

  return (<div className={"flex flex-row gap-2 bg-lightestGray dark:bg-neutral-50 w-full p-3 items-center text-gray dark:text-neutral-600 text-sm text-normal mb-5"}>
    <div className="font-medium">API key:</div>
    <div>{apiKey}</div>
    <div className="w-5 h-5 cursor-pointer" onClick={copyApiKeyToClipboard}>
      <CopyIcon className="w-5 h-5" />
    </div>
  </div>);
};

const TryItResultStatus = ({ status, statusText }: { status: number, statusText: string }) => {
  const color = status === 200 ? 'text-green dark:text-success-600' : 'text-red dark:text-error-600';
  const message = `${status} ${statusText}!`;
  return (
    <span>
      <span>Status: </span>
      <span className={color}>{message}</span>
    </span>
  );
};

type SelectLanguageListboxProps = {
  value: string | undefined,
  options: { value: string | undefined, text: string }[],
  callback: (selected: string | undefined) => void,
  buttonTestId?: string
};

export const codeViewlistboxButtonClasses = "cursor-pointer px-2.5 py-2.5 bg-codeViewPurple dark:bg-accent-900 border pr-10 text-left  relative border-lightGray dark:border-neutral-500 flex items-center gap-x-2 sm:text-sm";
export const codeViewlistboxOptionsClasses = "absolute py-1 mt-1 overflow-auto text-base bg-codeViewPurple dark:bg-accent-900 shadow-lg ring-1 ring-lightGray dark:ring-neutral-500 focus:outline-none sm:text-sm z-50"
export const codeViewShortListboxOptionsClasses = cx(codeViewlistboxOptionsClasses, "h-44");
export const codeViewlistboxOptionClasses = "py-2.5 px-4 hover:bg-brandPrimary dark:hover:bg-primary-600 hover:text-white cursor-pointer transition z-50"

const SelectLanguageListbox = ({ value, options, callback, buttonTestId }: SelectLanguageListboxProps) => {
  return (<Listbox value={value} onChange={callback}>
    <div className="">
      <Listbox.Button className={codeViewlistboxButtonClasses} data-testid={buttonTestId}>
        <ListboxButton content={pickOption(options, value)} />
      </Listbox.Button>
      <OptionsTransition>
        <Listbox.Options className={codeViewShortListboxOptionsClasses}>
          {
            options.map((option) => {
              return (
                <Listbox.Option key={option.value || 'none'} className={codeViewlistboxOptionClasses} value={option.value}>
                  <ListboxElement primaryText={option.text} />
                </Listbox.Option>
              );
            })
          }
        </Listbox.Options>
      </OptionsTransition>
    </div>
  </Listbox>);
};

const AdditionalOptionsTextArea = ({ value, placeholder, callback }: { value: string | number | undefined, placeholder: string, callback: (value: string | undefined) => void }) => {
  return (<div className="w-full">
    <textarea className="w-full border border-lightest shadow placeholder-gray-200 dark:placeholder-neutral-200 text-sm p-2" placeholder={placeholder} value={value} onChange={(ev) => {
      const newValue = ev.target.value;
      if (newValue === "") {
        callback(undefined);
      } else {
        callback(newValue);
      }
    }} />
  </div>);
};

export type ApiPlaygroundLocationState = {
  language: SupportedLanguages;
  scrapingMethod: ScrapingMethod;
  collectorType: CollectorType;
  url: string;
};

export function ApiPlaygroundEditDetails() {
  // const [ collectorType, setCollectorType ] = useState<CollectorType>("async_urls");
  // const newApiCallConfig = useNewApiCallConfig(collectorType);
  const isMobile = useIsMobile();
  const apiPlaygroundSettings = useApiPlaygroundSettings();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const currentType = apiPlaygroundSettings.type;
    const handleAsyncType = (type: string) => {
      const currentDescriptor = getSDEParamsForAsyncType(type);
      if (currentDescriptor?.asyncType) {
        const descriptorDetails = snakeCaseToCamelCase(getSDEPropertiesSchema(currentDescriptor.asyncType));
        removeNonMatchingProperties(apiPlaygroundSettings.apiParams, descriptorDetails);
      }
    };
    const handleProjectType = (type: string) => {
      const currentDescriptor = getSDEDescriptorForProjectType(type);
      if (currentDescriptor) {
        const descriptorDetails = snakeCaseToCamelCase(getSDEPropertiesSchema(currentDescriptor.projectType));
        removeNonMatchingProperties(apiPlaygroundSettings.apiParams, descriptorDetails);
      }
    };

    if (currentType === 'async_urls') handleAsyncType(currentType);
    else handleProjectType(currentType)
    // eslint-disable-next-line
  }, [apiPlaygroundSettings.type]);

  const user = useUser();
  const navigate = useNavigate();

  // const locationState = useLocation().state as ApiPlaygroundLocationState | undefined;
  // const [scrapingMethod, setScrapingMethod] = useLocalStorage<ScrapingMethod>('apiRequestScrapingMethod', newApiCallConfig.scrapingMethod);
  // const [defaultSDECollectorType, setDefaultSDECollectorType] = useLocalStorage<CollectorType>('apiPlaygroundScrapingType', 'async_google_search');
  // const [apiCallConfig, setApiCallConfig] = useState<ApiCallConfig>({ ...newApiCallConfig, scrapingMethod });

  const [detailsOpen, setDetailsOpen] = useLocalStorage<'up' | 'down'>('editProjectShowDetails', 'up');
  // const [language, setLanguage] = useLocalStorage<SupportedLanguages>('apiRequestSelectedLanguage', 'python');

  // useEffect(() => {
  //   setLanguage(locationState?.language || language);
  //   setScrapingMethod(locationState?.scrapingMethod || scrapingMethod);
  //   setApiCallConfig({
  //     input: locationState?.url || apiCallConfig.input,
  //     scrapingMethod: locationState?.scrapingMethod || apiCallConfig.scrapingMethod,
  //     config: { ...apiCallConfig.config, type: locationState?.collectorType || apiCallConfig.config.type },
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [ locationState?.language, locationState?.scrapingMethod, locationState?.collectorType ]);

  const [costCalculationProblems, setCostCalculationProblems] = useState<ConfigProblemWithMessage[]>([]);
  const problems = [...costCalculationProblems];

  // const [inputProblemMessage, setInputProblemMessage] = useState<string|undefined>();
  const [cost, setCost] = useState<number | undefined>(undefined);
  const [costLoading, setCostLoading] = useState<boolean>(false);
  const [initialParams, setInitialParams] = useState<any>(null);

  const [tryItResult, setTryItResult] = useState<TryItResult | undefined>(undefined);
  const [tryItInProgress, setTryItInProgress] = useState<boolean>(false);

  const updateApiParams = (key: string) => (newValue: number | string | boolean | undefined, apiParamsToUse?: AsyncApiParams) => {
    const oldApiParams = apiParamsToUse ?? apiPlaygroundSettings.apiParams;
    const currentUrlParams = Object.fromEntries(searchParams.entries() as any);

    // update actual api parameters
    const newApiParams = {
      ...oldApiParams,
      [key]: newValue,
    };

    // update url parameters with new value
        const newUrlParams = {
      ...currentUrlParams,
      [key]: newValue
    }

    // remove undefined values
    removeUnneededValues(key, newValue, newUrlParams);

    // update new url params
    setSearchParams(new URLSearchParams(newUrlParams));

    // make sure that the values are a number.
    if ((key === 'longitude' || key === 'latitude') && typeof newValue === 'string') {
      newApiParams[key] = parseFloat(newValue);
    }

    // False should be removed
    removeUnneededValues(key, newValue, newApiParams);

    trackApiPlaygroundParameterChanged(key, newValue);
    apiPlaygroundSettings.setApiParams(newApiParams);

    return newApiParams;
  }


  // TODO: This is too big. Cost calculation should not need that much data
  const apiCallConfigToProjectConfigForCostCalculation = (apiPlaygroundSettings: ApiPlaygroundSettings): CostCalculationProject => {
    return {
      language: apiPlaygroundSettings.language,
      name: '',
      userId: 0,
      scrapingInterval: 'daily',
      cron: "0 1 * * *",
      enabled: false,
      supposedToRunAt: undefined,
      input: { type: 'list_literal', list: apiPlaygroundSettings.input || "" },
      config: { type: apiPlaygroundSettings.type, apiParams: apiPlaygroundSettings.apiParams },
      output: { type: 'save' },
      demoProject: false,
      notificationConfig: {
        notifyOnSuccess: 'never',
        notifyOnFailure: 'with_every_run',
        notificationChanged: new Date(),
      },
    }
  };

  // Update url params ------------------------- 
  useEffect(() => {
    // if url params exist, then update.
    const { setInput, setType, setScrapingMethod } = apiPlaygroundSettings;
    const params = Object.fromEntries(searchParams.entries());
    const { input, method } = params;

    convertStringsInObject(params);

    // followRedirect is true by default so not needed if enabled in api params.
    if (params.followRedirect) {
      delete params.followRedirect;
    }

    if (input) {
      setInput(input);
    } else setInput('');

    // will use memory based method instead
    if (!method) return;

    if (method === 'api' || method === 'async' || method === 'proxy_mode') {
      setScrapingMethod(method);
    } else setScrapingMethod('structured_data_endpoint'); 

    setType(method);
    setInitialParams(params);
    // eslint-disable-next-line
  }, [])

    // used to wait x amount of time and then update, rather than on each keystroke. 
    const debouncedInput = useDebouncer(apiPlaygroundSettings.input, 1000);

    useEffect(() => {
      // this useEffect listens to method and input changes.
      // initially, it will listen to initialParams and update the apiParams
      // thereafter, will listen to any method/input change and update.
      const updateUrlParams = () => {
        const { apiParams, scrapingMethod } = apiPlaygroundSettings;
        const params = initialParams ? { ...initialParams } : { ...apiParams };
    
        // set initial param data to null after loading in so doesn't keep using the smae data.
        if (initialParams) setInitialParams(null);
    
        const { type, sdeType, ...rest } = params;
    
        if (scrapingMethod === 'structured_data_endpoint') {
          rest.method = apiPlaygroundSettings.sdeType;
    
          // autoparse not needed for sde
          if (rest.autoparse) delete rest.autoparse;
        } else {
          rest.method = scrapingMethod;
    
          // set autoparse to true for non sde scraping method
          if (rest?.outputFormat === 'csv' || rest?.outputFormat === 'json') rest.autoparse = true;
          else delete rest.autoparse;

          if (rest.tld) {
            delete rest.tld;
          }

          // US or EU is only supported for non SDE scrapes.
          // if countrycode is not US or EU then remove when switching to non sde.
          if (rest.countryCode && !['us', 'eu'].includes(rest.countryCode.toLowerCase())) {
            delete rest.countryCode;
          }
        };
    
        if (apiPlaygroundSettings.input) rest.input = debouncedInput;
        if (!apiPlaygroundSettings.input) delete rest.input;
    
        // this is done to update the apiParams when the initialParams are there (on load).
        const {method, input, ...filteredParams} = rest;
    
        return { urlParams: rest, apiParams: filteredParams };
      }
      
      const { urlParams, apiParams } = updateUrlParams();
  
      // update if the initialParams are there
      // and update with the correctly formatted data
      apiPlaygroundSettings.setApiParams(apiParams);
  
      // update Url Params
      setSearchParams(new URLSearchParams(urlParams));
      // eslint-disable-next-line
    }, [
      initialParams,
      debouncedInput,
      apiPlaygroundSettings.scrapingMethod,
      apiPlaygroundSettings.sdeType,
      apiPlaygroundSettings.type,
      apiPlaygroundSettings.setApiParams
    ])

  // ------------------------- Update url params

  // Cost calculation --------------------------

  const debouncedApiParams = useDebouncer(apiPlaygroundSettings.apiParams);
  const debouncedLanguage = useDebouncer(apiPlaygroundSettings.language);

  // TODO should be replaced by a Form and related action/loader
  useEffect(() => {
    if ((apiPlaygroundSettings.input || "").trim().length === 0) {
      setCost(undefined);
      setCostCalculationProblems([]);
      return;
    }
    setCostLoading(true);
    const controller = new AbortController();
    scraperApi.hostedScraping.projectCost(apiCallConfigToProjectConfigForCostCalculation(apiPlaygroundSettings), 'api_playground', { signal: controller.signal })
      .then((response) => {
        setCost(response.cost);
        if (response.errorMessages && response.errorMessages.length > 0) {
          setCostCalculationProblems([{ problem: ConfigProblem.BackendCostCalculationError, multipleMessages: response.errorMessages }]);
        } else {
          setCostCalculationProblems([]);
        }
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          console.error(err);
          setCostCalculationProblems([{ problem: ConfigProblem.BackendCostCalculationError, message: 'Network error' }]);
        }
      })
      .finally(() => setCostLoading(false));
    return () => { controller.abort(); };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInput, debouncedApiParams, debouncedLanguage]); // proj is deliberately not included!

  // useEffect( () => {
  //   if (apiCallConfig.config.type === 'async_urls' && scrapingMethod === 'structured_data_endpoint') {
  //     setApiCallConfig({...apiCallConfig, config: {...apiCallConfig.config, type: defaultSDECollectorType}});
  //   }
  //   if (apiCallConfig.config.type !== 'async_urls' && scrapingMethod !== 'structured_data_endpoint') {
  //     setApiCallConfig({...apiCallConfig, config: {...apiCallConfig.config, type: 'async_urls'}});
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [apiCallConfig.config.type, scrapingMethod]);


  if (user === null || user === undefined) {
    return <FullWidthLoadingSpinner />;
  }

  const showDetailsClicked = () => {
    setDetailsOpen(detailsOpen === 'up' ? 'down' : 'up');
  }

  // https://www.iana.org/assignments/media-types/media-types.xhtml
  const fileNameAndMimeTypeForLanguage = (language: SupportedLanguages): { fileName: string, mimeType: string } => {
    switch (language) {
      case 'curl': return { fileName: 'scrape.sh', mimeType: 'text/x-sh' };
      case 'python': return { fileName: 'scrape.py', mimeType: 'text/x-python' };
      case 'nodejs': return { fileName: 'scrape.js', mimeType: 'text/javascript' };
      case 'php': return { fileName: 'scrape.php', mimeType: 'text/x-php' };
      case 'ruby': return { fileName: 'scrape.rb', mimeType: 'application/x-ruby' };
      case 'java': return { fileName: 'Scrape.java', mimeType: 'text/x-java' };
    }
  }

  const doDownloadCode = () => {
    if (templateContent === undefined) {
      return;
    }
    const { fileName, mimeType } = fileNameAndMimeTypeForLanguage(apiPlaygroundSettings.language);
    const blob = new Blob([templateContent], {
      type: `${mimeType};charset=utf-8`
    });
    trackApiPlaygroundDataCopied('downloadButton', toApiCallConfig(apiPlaygroundSettings), cost);
    saveAs(blob, fileName);
  };

  const scheduleAsHostedScraperProject = () => {
    navigate('/projects/new', { state: { apiCallConfig: toApiCallConfig(apiPlaygroundSettings) } });
  }

  const tryIt = async () => {
    if (templateContent === undefined) {
      return;
    }
    setTryItInProgress(true);
    setTimeout(() => {
      document.getElementById('api-playground-status-container')?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
    try {
      // TODO: proper signal, that goes away when the user navigates away
      const controller = new AbortController();
      const tryItResult: TryItResult = await scraperApi.hostedScraping.tryIt(toApiCallConfig(apiPlaygroundSettings), { signal: controller.signal }) as TryItResult;
      setTryItResult(tryItResult);
      setTimeout(() => {
        document.getElementById('api-playground-status-container')?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    } finally {
      setTryItInProgress(false);
    }
  };

  const removeUnneededValues = (key: unknown, newValue: number | string | boolean | undefined, newApiParams: AsyncApiParams): void => {
    const isFalsyvalue = typeof newValue === 'undefined'
      || (typeof newValue === 'boolean' && !newValue)
      || (typeof newValue === 'string' && newValue === '');

    if (key !== 'followRedirect' && isFalsyvalue) {
      delete newApiParams[key as keyof typeof newApiParams];
    } else if (key === 'followRedirect' && newValue === true) {
      delete newApiParams[key as keyof typeof newApiParams];
    }
  }

  const changeConfigType = (cm: any) => {
    if(!cm) return;
    trackApiPlaygroundSdeSelected(cm.value as string);
    apiPlaygroundSettings.setType(cm.value);
  }

  // const changeScrapingMethod = (sm: ScrapingMethod) => {
  //   setScrapingMethod(sm);
  //   setApiCallConfig({...apiCallConfig, scrapingMethod: sm});
  // }

  const updateInput = (input: string | undefined) => {
    trackApiPlaygroundURLEntered(input);
    apiPlaygroundSettings.setInput(input || "");
  };

  const updateSelectedLanguage = (lang: string | undefined) => {
    apiPlaygroundSettings.setLanguage(lang as SupportedLanguages);
    trackApiPlaygroundLanguageSelectorClicked(lang);
  };

  const inputSectionLabels = getInputSectionLabelsForAP(apiPlaygroundSettings.type);

  const templateContent = sampleCode(
    apiPlaygroundSettings.language,
    apiPlaygroundSettings.scrapingMethod,
    apiPlaygroundSettings.type,
    apiPlaygroundSettings.input,
    user.apiKey,
    apiPlaygroundSettings.apiParams
  );

  const copyCodeToClipboard = () => {
    if (templateContent === undefined) {
      return;
    }

    trackApiPlaygroundCopyToClipboardClicked();
    trackApiPlaygroundDataCopied('copyButton', toApiCallConfig(apiPlaygroundSettings), cost);
    navigator.clipboard.writeText(templateContent)
      .then(() => {
          Toaster.success('Code copied to clipboard');
      })
      .catch((error) => {
        console.error('Error copying to clipboard', error);
        Toaster.error('Code copy failed');
      });
  };

  const shareCodeViaMobile = () => {
    if(templateContent === undefined) return;
    
    shareOnMobile({
    title: "Your ScraperAPI Code",
    text: templateContent
  });
}

  const copyResultToClipboard = () => {
    const result = tryItResult?.responseText;
    if (result === undefined) {
      return;
    }
    navigator.clipboard.writeText(result)
      .then(() => {
        Toaster.success('Scraping result copied to clipboard');
      })
      .catch((error) => {
        console.error('Error copying to clipboard', error);
        Toaster.error('Code copy failed');
      });
  };

  const inputErrors = validateInput(apiPlaygroundSettings.type, { type: 'list_literal', list: apiPlaygroundSettings.input });
  const areSettingsValid = inputErrors.length === 0;
  const codeViewContent = areSettingsValid ? templateContent : undefined;
  const codeViewPlaceholder = areSettingsValid ? undefined : codeViewPlaceholderMessage(apiPlaygroundSettings.scrapingMethod, apiPlaygroundSettings.type);

  const rightSidebar =
    <ProjectSummarySidebar
      variant="api-playground"
      details={[]}
      config={apiPlaygroundSettings}
      cost={cost}
      costInProgress={costLoading}
    />;

  const actionButtons =
    (<div className="flex flex-row flex-wrap justify-between">
      <div>
        <Button text="Cancel" centerAlign className="button button-tertiary" href="/" size="LG" />
      </div>
      <div className="flex flex-row gap-2">
        <Button text="Schedule as a DataPipeline project" className="button button-secondary" onClick={scheduleAsHostedScraperProject} size="LG" />
        <Button text="Download code" className="button button-primary" onClick={doDownloadCode} size="LG" />
      </div>
    </div>);

  return (
    <BorderLayout
      layout="horizontal"
      wrap="xl"
      bottom={
        <div className="px-8 py-5 space-y-5">
          <div className="w-full border-t border-slate-200" />
          {actionButtons}
        </div>
      }
      right={<div className="w-full xl:w-80 xl:h-full">{rightSidebar}</div>}
    >
      <ScrollToHeaderTitle />
      <BorderedPage
        title="API Playground"
      >
        <div className="px-4">
          <Alert className="my-3"
            description="When using the API Playground, it is important to remember that you must run the code by yourself. The code must be copied to your clipboard and the request must be submitted to the server by yourself." />
          <ProjectDetailsHead variant="api-playground" configType={apiPlaygroundSettings.type} />

          <Separator />
          <SectionTitle number={1} title="Build request" />
          {(problems.some(p => p.problem === ConfigProblem.InvalidInput))
            ? (<div className="text-red dark:text-error-600">Invalid input</div>)
            : <></>
          }
          <Section>
            <InputComments title={inputSectionLabels.inputSectionTitle} description={inputSectionLabels.inputSectionDescription} />
            <SectionRightHandSide>
              <div>Select scraping method</div>
              <RadioGroup className="hidden dark:visible" type="button" name='scrapingMethod' value={apiPlaygroundSettings.scrapingMethod} onChange={apiPlaygroundSettings.setScrapingMethod}>
                <RadioGroupElement value="api" label="API" />
                <RadioGroupElement value="async" label="Async" />
                <RadioGroupElement value="proxy_mode" label="Proxy mode" />
                <RadioGroupElement value="structured_data_endpoint" label="Structured Data Endpoints" />
              </RadioGroup>
              <RadioGroup className="visible dark:hidden" name='scrapingMethod' value={apiPlaygroundSettings.scrapingMethod} onChange={apiPlaygroundSettings.setScrapingMethod}>
                <RadioGroupElement value="api" label="API" />
                <RadioGroupElement value="async" label="Async" />
                <RadioGroupElement value="proxy_mode" label="Proxy mode" />
                <RadioGroupElement value="structured_data_endpoint" label="Structured Data Endpoints" />
              </RadioGroup>
              {
                apiPlaygroundSettings.scrapingMethod === 'structured_data_endpoint' &&
                <ApiPlaygroundComboBox 
                  data={sdeCollectorTypes} 
                  value={apiPlaygroundSettings.type}
                  onChange={changeConfigType}
                  type='method'
                  restoreOnBlurOrEscape={true}
                  testId="sde-combobox"
                />
              }
              <div className="my-2">{enterInputsMessage(apiPlaygroundSettings.scrapingMethod, apiPlaygroundSettings.type)}<span className="text-brandPrimary dark:text-primary-600"> (Required)</span></div>
              {
                apiPlaygroundSettings.scrapingMethod === 'async'
                  ? <AdditionalOptionsTextArea value={apiPlaygroundSettings.input} placeholder={enterInputsPlaceholder(apiPlaygroundSettings.scrapingMethod, apiPlaygroundSettings.type)} callback={updateInput} />
                  : <AdditionalOptionsTextBox value={apiPlaygroundSettings.input} placeholder={enterInputsPlaceholder(apiPlaygroundSettings.scrapingMethod, apiPlaygroundSettings.type)} callback={updateInput} />
              }
              <ErrorProblemsBox allProblems={problems} interestingProblems={[ConfigProblem.BackendCostCalculationError]} breakAll />
            </SectionRightHandSide>
          </Section>

          <Separator />

          <Section>
            <InputComments
              title="Additional options &amp; filters"
              description={<p>Fine-tune your web scraping with additional options and parameters.</p>}
              showDetailsSwitch={detailsOpen}
              onDetailsClicked={showDetailsClicked}
              testId="testAdditionalOptionsAndFilters" />
            <SectionRightHandSide>
              <div className="">Select advanced options</div>
              {detailsOpen === 'up' && isAmazonProject(apiPlaygroundSettings.type) &&
                (<AmazonProjectAsyncApiParams collectorConfig={apiPlaygroundSettings} problems={problems} updateApiParams={updateApiParams} checkSubscription={false} withOutputFormat />)}
              {detailsOpen === 'up' && isGoogleProject(apiPlaygroundSettings.type) &&
                (<GoogleProjectAsyncApiParams collectorConfig={apiPlaygroundSettings} problems={problems} updateApiParams={updateApiParams} checkSubscription={false} withOutputFormat />)}
              {detailsOpen === 'up' && isAsyncUrlsProject(apiPlaygroundSettings.type) &&
                (<UrlProjectAsyncApiParams variant="api-playground" collectorConfig={apiPlaygroundSettings} problems={problems} updateApiParams={updateApiParams} />)}
              {detailsOpen === 'up' && isWalmartProject(apiPlaygroundSettings.type) &&
                (<WalmartProjectAsyncApiParams collectorConfig={apiPlaygroundSettings} problems={problems} updateApiParams={updateApiParams} withOutputFormat />)}
              {detailsOpen === 'up' && isEbayProject(apiPlaygroundSettings.type) &&
                (<EbayProjectAsyncApiParams collectorConfig={apiPlaygroundSettings} problems={problems} updateApiParams={updateApiParams} withOutputFormat />)}
              {detailsOpen === 'up' && isRedfinProject(apiPlaygroundSettings.type) &&
                (<RedfinProjectAsyncApiParams collectorConfig={apiPlaygroundSettings} problems={problems} updateApiParams={updateApiParams} checkSubscription={false} withOutputFormat />)}
            </SectionRightHandSide>
          </Section>

          <Separator />

          {/* <SectionTitle number={ 2 } title="Output settings"/>
          <Section>
            <InputComments title="Choose the result format" description="Only applicable when the results are parsed" />
            <SectionRightHandSide className='w-full'>
              {
                (isSDEProject(apiCallConfig.config.type)
                || (isAsyncUrlsProject(apiCallConfig.config.type) && Boolean(apiCallConfig.config.apiParams?.autoparse))
                || (apiCallConfig.scrapingMethod === 'async' && Boolean(apiCallConfig.config.apiParams?.autoparse))
                || (apiCallConfig.scrapingMethod === 'proxy_mode' && Boolean(apiCallConfig.config.apiParams?.autoparse))) &&
                (<>
                  <div className="mt-5">
                    Select format
                  </div>
                  <AdditionalOptionsListbox
                    value={apiCallConfig.config?.apiParams?.outputFormat === 'csv' ? 'csv' : 'json'}
                    options={[
                      { value: 'csv', text: 'CSV' },
                      { value: 'json', text: 'JSON' }
                    ]}
                    callback={updateApiParams('outputFormat')}
                    />
                  </>)
              }
            </SectionRightHandSide>
          </Section> */}

          <SectionTitle number={2} title="Integrate into codebase" />
          <Section>
            <SectionRightHandSide className='w-full'>
              <ApikeyBox apiKey={user.apiKey ?? '?'} />

              <div className="w-full dark:border dark:border-gray-200">
                <div className="flex flex-row justify-between p-2.5 bg-codeViewPurple dark:bg-neutral-50 text-white">
                  <SelectLanguageListbox value={apiPlaygroundSettings.language} options={supportedLanguages} callback={updateSelectedLanguage} />
                  <div className="flex flex-row gap-x-2">
                    <PureTooltip content={!areSettingsValid && 'The data provided is incomplete or invalid. Please review your inputs and try again.'}>
                      <Button disabled={!areSettingsValid} text="Try it" icon={{ Icon: PlayIcon }} centerAlign className="button button-codeeditorplay dark:button-primary" onClick={tryIt} size="MD" />
                    </PureTooltip>
                    <PureTooltip content={!areSettingsValid && 'The data provided is incomplete or invalid. Please review your inputs and try again.'}>
                      {isMobile
                        ? <Button icon={{ Icon: ShareIcon }} disabled={!areSettingsValid} text="Share code" centerAlign className="ml-2 button button-codeeditor dark:button-secondary" onClick={shareCodeViaMobile} size="MD" />
                        : <Button icon={{ Icon: CopyIcon }} disabled={!areSettingsValid} text="Copy to clipboard" centerAlign className="ml-2 button button-codeeditor dark:button-secondary" onClick={copyCodeToClipboard} size="MD" />}
                    </PureTooltip>
                  </div>
                </div>
                <CodeView content={codeViewContent} placeholder={codeViewPlaceholder} language={apiPlaygroundSettings.language} minHeight="10rem" />
              </div>

              <div id="api-playground-status-container" className="my-5">
                {tryItInProgress &&
                  <div className="flex flex-row items-center gap-2">
                    <div className=""><Spinner className="w-5 h-5 animate-spin text-neutral-500 dark:text-neutral-500" /></div>
                    <div className="">Scraping in progress</div>
                  </div>}
                {!tryItInProgress && tryItResult &&
                  <TryItResultStatus status={tryItResult.status} statusText={tryItResult.statusText} />
                }
              </div>

              {tryItResult && !tryItInProgress &&
                <div id="apiplayground-try-it-result-container" className="w-full dark:border dark:border-gray-200">
                  <div className="flex flex-row justify-between p-2.5 bg-codeViewPurple dark:bg-neutral-50 text-white">
                    <div />
                    <Button icon={{ Icon: CopyIcon }} text="Copy to clipboard" centerAlign className="button button-secondary ml-2" onClick={copyResultToClipboard} size="MD" />
                  </div>
                  <CodeView content={tryToFormatJSON(tryItResult.responseText)} placeholder="" language={isJson(tryItResult.responseText) ? "json" : "html"} minHeight="10rem" />
                </div>
              }

            </SectionRightHandSide>
          </Section>
        </div>

      </BorderedPage>
    </BorderLayout>
  );

}
