export const async_redfin_search = {
  input: "https://www.redfin.com/city/1826/MA/Boston/filter/min-beds=3,min-baths=2",
  result: "{\n" +
    "  \"url\": \"https://www.redfin.com/city/1826/MA/Boston/filter/min-beds=3,min-baths=2\",\n" +
    "  \"type\": \"search_for_sale\",\n" +
    "  \"listing\": [\n" +
    "    {\n" +
    "      \"address\": \"370 Beacon St #3, Boston, MA 02116\",\n" +
    "      \"phone\": \"\",\n" +
    "      \"number_beds\": \"3 beds\",\n" +
    "      \"number_baths\": \"4 baths\",\n" +
    "      \"sq_ft\": \"3,300 sq ft\",\n" +
    "      \"thumbnail_img_url\": \"https://ssl.cdn-redfin.com/system_files/media/1045768_JPG/genDesktopMapHomeCardUrl/item_29.jpg\",\n" +
    "      \"price\": [\n" +
    "        {\n" +
    "          \"cost\": 5899000,\n" +
    "          \"currency\": \"USD\",\n" +
    "          \"charge-rate\": \"one-time\",\n" +
    "          \"deal\": \"\"\n" +
    "        }\n" +
    "      ],\n" +
    "      \"badge\": [\n" +
    "        \"LISTED BY REDFIN\",\n" +
    "        \"3D WALKTHROUGH\"\n" +
    "      ],\n" +
    "      \"key_facts\": [\n" +
    "        \"$6,557 HOA\",\n" +
    "        \"Community pool\",\n" +
    "        \"Walker's paradise\"\n" +
    "      ],\n" +
    "      \"badge_sold\": \"\"\n" +
    "    },\n" +
    "    {\n" +
    "      \"address\": \"74 Green St, Boston, MA 02130\",\n" +
    "      \"phone\": \"\",\n" +
    "      \"number_beds\": \"4 beds\",\n" +
    "      \"number_baths\": \"3.5 baths\",\n" +
    "      \"sq_ft\": \"6,200 sq ft\",\n" +
    "      \"thumbnail_img_url\": \"https://ssl.cdn-redfin.com/photo/52/islphoto/722/genIslnoResize.73335722_0.jpg\",\n" +
    "      \"price\": [\n" +
    "        {\n" +
    "          \"cost\": 1850000,\n" +
    "          \"currency\": \"USD\",\n" +
    "          \"charge-rate\": \"one-time\",\n" +
    "          \"deal\": \"\"\n" +
    "        }\n" +
    "      ],\n" +
    "      \"badge\": [\n" +
    "        \"NEW 59 MINS AGO\"\n" +
    "      ],\n" +
    "      \"key_facts\": [\n" +
    "        \"4,160 sq ft lot\",\n" +
    "        \"3 garage spots\",\n" +
    "        \"Walker's paradise\"\n" +
    "      ],\n" +
    "      \"badge_sold\": \"\"\n" +
    "    },\n" +
    "    {\n" +
    "      \"address\": \"\",\n" +
    "      \"phone\": \"\",\n" +
    "      \"number_beds\": \"\",\n" +
    "      \"number_baths\": \"\",\n" +
    "      \"sq_ft\": \"\",\n" +
    "      \"price\": [],\n" +
    "      \"badge\": [],\n" +
    "      \"key_facts\": [],\n" +
    "      \"badge_sold\": \"\"\n" +
    "    },\n" +
    "    {\n" +
    "      \"address\": \"16 Lawrence St #2, Boston, MA 02129\",\n" +
    "      \"phone\": \"\",\n" +
    "      \"number_beds\": \"4 beds\",\n" +
    "      \"number_baths\": \"3.5 baths\",\n" +
    "      \"sq_ft\": \"2,298 sq ft\",\n" +
    "      \"thumbnail_img_url\": \"https://ssl.cdn-redfin.com/photo/52/islphoto/654/genIslnoResize.73335654_0.jpg\",\n" +
    "      \"price\": [\n" +
    "        {\n" +
    "          \"cost\": 1895000,\n" +
    "          \"currency\": \"USD\",\n" +
    "          \"charge-rate\": \"one-time\",\n" +
    "          \"deal\": \"\"\n" +
    "        }\n" +
    "      ],\n" +
    "      \"badge\": [\n" +
    "        \"OPEN SUN, 12PM TO 1:30PM\"\n" +
    "      ],\n" +
    "      \"key_facts\": [\n" +
    "        \"$250 HOA\",\n" +
    "        \"Walker's paradise\"\n" +
    "      ],\n" +
    "      \"badge_sold\": \"\"\n" +
    "    },\n" +
    "    {\n" +
    "      \"address\": \"5 Oakhurst St #2, Boston, MA 02124\",\n" +
    "      \"phone\": \"\",\n" +
    "      \"number_beds\": \"4 beds\",\n" +
    "      \"number_baths\": \"2 baths\",\n" +
    "      \"sq_ft\": \"1,597 sq ft\",\n" +
    "      \"thumbnail_img_url\": \"https://ssl.cdn-redfin.com/photo/52/islphoto/599/genIslnoResize.73335599_0.jpg\",\n" +
    "      \"price\": [\n" +
    "        {\n" +
    "          \"cost\": 549900,\n" +
    "          \"currency\": \"USD\",\n" +
    "          \"charge-rate\": \"one-time\",\n" +
    "          \"deal\": \"\"\n" +
    "        }\n" +
    "      ],\n" +
    "      \"badge\": [],\n" +
    "      \"key_facts\": [\n" +
    "        \"$275 HOA\",\n" +
    "        \"2 garage spots\",\n" +
    "        \"Somewhat walkable\"\n" +
    "      ],\n" +
    "      \"badge_sold\": \"\"\n" +
    "    },\n" +
    "    {\n" +
    "      \"address\": \"38 Montgomery St, Boston, MA 02116\",\n" +
    "      \"phone\": \"\",\n" +
    "      \"number_beds\": \"4 beds\",\n" +
    "      \"number_baths\": \"3 baths\",\n" +
    "      \"sq_ft\": \"3,061 sq ft\",\n" +
    "      \"thumbnail_img_url\": \"https://ssl.cdn-redfin.com/photo/52/islphoto/575/genIslnoResize.73335575_0.jpg\",\n" +
    "      \"price\": [\n" +
    "        {\n" +
    "          \"cost\": 3295000,\n" +
    "          \"currency\": \"USD\",\n" +
    "          \"charge-rate\": \"one-time\",\n" +
    "          \"deal\": \"\"\n" +
    "        }\n" +
    "      ],\n" +
    "      \"badge\": [],\n" +
    "      \"key_facts\": [\n" +
    "        \"Walker's paradise\"\n" +
    "      ],\n" +
    "      \"badge_sold\": \"\"\n" +
    "    },\n" +
    "    {\n" +
    "      \"address\": \"352 Marlborough St, Boston, MA 02115\",\n" +
    "      \"phone\": \"\",\n" +
    "      \"number_beds\": \"4 beds\",\n" +
    "      \"number_baths\": \"5.5 baths\",\n" +
    "      \"sq_ft\": \"4,754 sq ft\",\n" +
    "      \"thumbnail_img_url\": \"https://ssl.cdn-redfin.com/photo/52/islphoto/458/genIslnoResize.73335458_1.jpg\",\n" +
    "      \"price\": [\n" +
    "        {\n" +
    "          \"cost\": 9450000,\n" +
    "          \"currency\": \"USD\",\n" +
    "          \"charge-rate\": \"one-time\",\n" +
    "          \"deal\": \"\"\n" +
    "        }\n" +
    "      ],\n" +
    "      \"badge\": [\n" +
    "        \"OPEN SAT, 10:30AM TO 12:30PM\",\n" +
    "        \"VIDEO TOUR\"\n" +
    "      ],\n" +
    "      \"key_facts\": [\n" +
    "        \"2,576 sq ft lot\",\n" +
    "        \"Walker's paradise\"\n" +
    "      ],\n" +
    "      \"badge_sold\": \"\"\n" +
    "    },\n" +
    "    {\n" +
    "      \"address\": \"21 Goldfinch Ct #21, Boston, MA 02126\",\n" +
    "      \"phone\": \"\",\n" +
    "      \"number_beds\": \"3 beds\",\n" +
    "      \"number_baths\": \"2 baths\",\n" +
    "      \"sq_ft\": \"1,270 sq ft\",\n" +
    "      \"thumbnail_img_url\": \"https://ssl.cdn-redfin.com/photo/52/islphoto/448/genIslnoResize.73335448_0.jpg\",\n" +
    "      \"price\": [\n" +
    "        {\n" +
    "          \"cost\": 599000,\n" +
    "          \"currency\": \"USD\",\n" +
    "          \"charge-rate\": \"one-time\",\n" +
    "          \"deal\": \"\"\n" +
    "        }\n" +
    "      ],\n" +
    "      \"badge\": [],\n" +
    "      \"key_facts\": [\n" +
    "        \"$455 HOA\",\n" +
    "        \"Car-dependent\"\n" +
    "      ],\n" +
    "      \"badge_sold\": \"\"\n" +
    "    },\n" +
    "    {\n" +
    "      \"address\": \"252 Aspinwall Ave, Brookline, MA 02445\",\n" +
    "      \"phone\": \"\",\n" +
    "      \"number_beds\": \"9 beds\",\n" +
    "      \"number_baths\": \"2 baths\",\n" +
    "      \"sq_ft\": \"4,566 sq ft\",\n" +
    "      \"thumbnail_img_url\": \"https://ssl.cdn-redfin.com/photo/52/islphoto/417/genIslnoResize.73335417_0.jpg\",\n" +
    "      \"price\": [\n" +
    "        {\n" +
    "          \"cost\": 2300000,\n" +
    "          \"currency\": \"USD\",\n" +
    "          \"charge-rate\": \"one-time\",\n" +
    "          \"deal\": \"\"\n" +
    "        }\n" +
    "      ],\n" +
    "      \"badge\": [],\n" +
    "      \"key_facts\": [\n" +
    "        \"4,435 sq ft lot\",\n" +
    "        \"Very walkable\"\n" +
    "      ],\n" +
    "      \"badge_sold\": \"\"\n" +
    "    },\n" +
    "    {\n" +
    "      \"address\": \"29 E Cottage, Boston, MA 02125\",\n" +
    "      \"phone\": \"\",\n" +
    "      \"number_beds\": \"7 beds\",\n" +
    "      \"number_baths\": \"3 baths\",\n" +
    "      \"sq_ft\": \"2,832 sq ft\",\n" +
    "      \"thumbnail_img_url\": \"https://ssl.cdn-redfin.com/photo/52/islphoto/379/genIslnoResize.73335379_0.jpg\",\n" +
    "      \"price\": [\n" +
    "        {\n" +
    "          \"cost\": 1050000,\n" +
    "          \"currency\": \"USD\",\n" +
    "          \"charge-rate\": \"one-time\",\n" +
    "          \"deal\": \"\"\n" +
    "        }\n" +
    "      ],\n" +
    "      \"badge\": [],\n" +
    "      \"key_facts\": [\n" +
    "        \"3,717 sq ft lot\",\n" +
    "        \"Very walkable\"\n" +
    "      ],\n" +
    "      \"badge_sold\": \"\"\n" +
    "    },\n" +
    "    {\n" +
    "      \"address\": \"79 Parkton Rd #1, Boston, MA 02130\",\n" +
    "      \"phone\": \"\",\n" +
    "      \"number_beds\": \"3 beds\",\n" +
    "      \"number_baths\": \"2 baths\",\n" +
    "      \"sq_ft\": \"1,033 sq ft\",\n" +
    "      \"thumbnail_img_url\": \"https://ssl.cdn-redfin.com/photo/52/islphoto/309/genIslnoResize.73335309_0.jpg\",\n" +
    "      \"price\": [\n" +
    "        {\n" +
    "          \"cost\": 749900,\n" +
    "          \"currency\": \"USD\",\n" +
    "          \"charge-rate\": \"one-time\",\n" +
    "          \"deal\": \"\"\n" +
    "        }\n" +
    "      ],\n" +
    "      \"badge\": [],\n" +
    "      \"key_facts\": [\n" +
    "        \"$215 HOA\",\n" +
    "        \"Very walkable\"\n" +
    "      ],\n" +
    "      \"badge_sold\": \"\"\n" +
    "    },\n" +
    "    {\n" +
    "      \"address\": \"39 Juliette St #3, Boston, MA 02122\",\n" +
    "      \"phone\": \"\",\n" +
    "      \"number_beds\": \"3 beds\",\n" +
    "      \"number_baths\": \"3 baths\",\n" +
    "      \"sq_ft\": \"1,386 sq ft\",\n" +
    "      \"thumbnail_img_url\": \"https://ssl.cdn-redfin.com/photo/52/islphoto/296/genIslnoResize.73335296_0.jpg\",\n" +
    "      \"price\": [\n" +
    "        {\n" +
    "          \"cost\": 730000,\n" +
    "          \"currency\": \"USD\",\n" +
    "          \"charge-rate\": \"one-time\",\n" +
    "          \"deal\": \"\"\n" +
    "        }\n" +
    "      ],\n" +
    "      \"badge\": [],\n" +
    "      \"key_facts\": [\n" +
    "        \"1,386 sq ft lot\",\n" +
    "        \"$250 HOA\",\n" +
    "        \"Garage\"\n" +
    "      ],\n" +
    "      \"badge_sold\": \"\"\n" +
    "    },\n" +
    "    {\n" +
    "      \"address\": \"38 Kappius Path, Newton, MA 02459\",\n" +
    "      \"phone\": \"\",\n" +
    "      \"number_beds\": \"6 beds\",\n" +
    "      \"number_baths\": \"6.5 baths\",\n" +
    "      \"sq_ft\": \"5,500 sq ft\",\n" +
    "      \"thumbnail_img_url\": \"https://ssl.cdn-redfin.com/photo/52/islphoto/226/genIslnoResize.73335226_0.jpg\",\n" +
    "      \"price\": [\n" +
    "        {\n" +
    "          \"cost\": 2795000,\n" +
    "          \"currency\": \"USD\",\n" +
    "          \"charge-rate\": \"one-time\",\n" +
    "          \"deal\": \"\"\n" +
    "        }\n" +
    "      ],\n" +
    "      \"badge\": [],\n" +
    "      \"key_facts\": [\n" +
    "        \"7,000 sq ft lot\",\n" +
    "        \"2 garage spots\",\n" +
    "        \"Car-dependent\"\n" +
    "      ],\n" +
    "      \"badge_sold\": \"\"\n" +
    "    },\n" +
    "    {\n" +
    "      \"address\": \"10 Briarcliff Ter, Boston, MA 02126\",\n" +
    "      \"phone\": \"\",\n" +
    "      \"number_beds\": \"4 beds\",\n" +
    "      \"number_baths\": \"3 baths\",\n" +
    "      \"sq_ft\": \"1,756 sq ft\",\n" +
    "      \"thumbnail_img_url\": \"https://ssl.cdn-redfin.com/photo/52/islphoto/057/genIslnoResize.73335057_0.jpg\",\n" +
    "      \"price\": [\n" +
    "        {\n" +
    "          \"cost\": 580000,\n" +
    "          \"currency\": \"USD\",\n" +
    "          \"charge-rate\": \"one-time\",\n" +
    "          \"deal\": \"\"\n" +
    "        }\n" +
    "      ],\n" +
    "      \"badge\": [],\n" +
    "      \"key_facts\": [\n" +
    "        \"0.32 acre lot\",\n" +
    "        \"Car-dependent\"\n" +
    "      ],\n" +
    "      \"badge_sold\": \"\"\n" +
    "    },\n" +
    "    {\n" +
    "      \"address\": \"17 Thompson St, Boston, MA 02136\",\n" +
    "      \"phone\": \"\",\n" +
    "      \"number_beds\": \"4 beds\",\n" +
    "      \"number_baths\": \"2.5 baths\",\n" +
    "      \"sq_ft\": \"1,860 sq ft\",\n" +
    "      \"thumbnail_img_url\": \"https://ssl.cdn-redfin.com/photo/52/islphoto/015/genIslnoResize.73335015_1.jpg\",\n" +
    "      \"price\": [\n" +
    "        {\n" +
    "          \"cost\": 899000,\n" +
    "          \"currency\": \"USD\",\n" +
    "          \"charge-rate\": \"one-time\",\n" +
    "          \"deal\": \"\"\n" +
    "        }\n" +
    "      ],\n" +
    "      \"badge\": [],\n" +
    "      \"key_facts\": [\n" +
    "        \"6,250 sq ft lot\",\n" +
    "        \"Community pool\",\n" +
    "        \"Somewhat walkable\"\n" +
    "      ],\n" +
    "      \"badge_sold\": \"\"\n" +
    "    },\n" +
    "  ]\n" +
    "}"
};
