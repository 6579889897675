import { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from "@headlessui/react";
import { cx } from "utils";
import {
    comboboxButtonClasses,
    dataSelectedListboxOptionClasses,
    listboxOptionClasses,
    OptionsTransition,
    shortListboxOptionsClassesOverflowing
} from "components/Listbox";

interface DataItem {
    text?: string,
    value?: string
}

interface ComboBoxProps {
    value?: any;
    data: DataItem[];
    type?: string;
    onChange: any;
    restoreOnBlurOrEscape?: boolean;
    placeholder?: string;
    testId: string;
}

const ApiPlaygroundComboBox = ({
    value,
    data,
    onChange,
    type,
    restoreOnBlurOrEscape = false,
    placeholder = "",
    testId,
}: ComboBoxProps) => {
    const [query, setQuery] = useState<string>("");
    const [selected, setSelected] = useState<DataItem | null | undefined>(null);
    const [open, setIsOpen] = useState<boolean>(false);
    const [showFullList, setShowFullList] = useState<boolean>(true);

    // using useCallback to reduce unnecessary re-renders.
    const updateSelectedData = useCallback(() => {
        if (data && value) {
            const foundItem = { ...data.find((item: DataItem) => item?.value === value || item === value) };

            if (foundItem?.text && foundItem.text !== "None") {
                setSelected(foundItem);
                setQuery(foundItem.text || "");
            }
        }
    }, [data, value, setSelected, setQuery]);


    // Update selected item when external value changes
    // for example if the value is from the url parameters.
    useEffect(() => {
        updateSelectedData();
    }, [updateSelectedData]);

    // seem to be having an issue with duplicated data for some options;
    // (google search, domain list for example would show more than one ci, but specifically ci?)
    // so this ensures that the data shown maps out the duplicated items shown in the dropdowns.
    const deduplicatedData = _.uniq(data);

    // Filter options based on query or show full list
    const filteredData = showFullList ? deduplicatedData
        : deduplicatedData?.filter((item: any) => item?.value?.toLowerCase().includes(query.toLowerCase())) || [];

    // Reset query to selected item if invalid or empty
    const resetToSelected = () => {
        const isValid = data.some((item: any) => item.text.toLowerCase() === query.toLowerCase());
        setQuery(isValid ? query : selected?.text || "");
    };

    // Handle item selection
    const handleOnChange = (item: any) => {
        if (!item || (item.text === 'None' || item.text === 'none')) {
            if (!restoreOnBlurOrEscape) {
                setQuery("");
                setSelected(null);

                // expected change value
                onChange(undefined);
                setIsOpen(false);
            } else {
                setIsOpen(true);
            }
            return;
        }
        setIsOpen(false);
        setSelected(item);
        setQuery(item.text);

        // pass in the value or the actual method thats used.
        onChange(type === "apiParams" ? item.value : item);
    };

    // Handle input change
    const handleInputChange = (value: string) => {
        setQuery(value);
        setIsOpen(true);
        // show the filtered data
        setShowFullList(false);
    };

    // Handle input blur
    const handleOnBlur = () => {
        setTimeout(() => {
            // this prop check is used to reset the value is required or not (example, selecting an SDE) - 
            // it will always want to be required
            if (restoreOnBlurOrEscape) resetToSelected();
            else setQuery(selected?.text || "");
            setIsOpen(false);
            setShowFullList(true);
        }, 200);
    };

    return (
        <Combobox value={selected} onChange={handleOnChange}>
            <div data-testid={testId} className={comboboxButtonClasses}>
                <ComboboxInput
                    className='w-full border-[#99ADBF]'
                    autoFocus={false}
                    value={query}
                    onChange={(e) => handleInputChange(e.target.value)}
                    onClick={() => setIsOpen(true)}
                    onBlur={handleOnBlur}
                    placeholder={placeholder}
                />
            </div>

            {open && filteredData.length > 0 && (
                <OptionsTransition>
                    <ComboboxOptions static className={shortListboxOptionsClassesOverflowing}>
                        {filteredData.map((filteredItem: any, idx: number) => (
                            <ComboboxOption
                                key={`${filteredItem.value || "empty"}-${idx}`}
                                as="div"
                                value={filteredItem}
                                className={({ active }) => cx(listboxOptionClasses, dataSelectedListboxOptionClasses, active && "bg-primary-600 text-white")}
                            >
                                {filteredItem.text}
                            </ComboboxOption>
                        ))}
                    </ComboboxOptions>
                </OptionsTransition>
            )}
        </Combobox>
    );
};

export default ApiPlaygroundComboBox;
