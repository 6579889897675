import { createContext, ReactNode, useCallback, useContext, useState } from "react";
import Cookies from "js-cookie";

import { useFeatureSwitch } from "components/FeatureSwitch";

import { getCookie, setCookie } from "utils/cookieUtils";
import { trackNewDesignBannerOptionClicked } from "utils/Tracking";

type NewDashboardDesignSetting = "yes" | "no" | "never";

const cookieName = "saTryNewDashboardDesign";

type NewDashboardDesignContextType = {
  newDashboardDesignSetting: NewDashboardDesignSetting | undefined;
  newDesignForced: boolean;
  updateNewDashboardDesignSetting: (newValue: NewDashboardDesignSetting) => void;
};

let NewDashboardDesignContext = createContext<NewDashboardDesignContextType>(null!);

export default function NewDashboardDesignProvider({ children }: { children?: ReactNode }) {
  const [ newDashboardDesignSetting, setNewDashboardDesignSetting ] = useState<NewDashboardDesignSetting>(getCookie(cookieName) as NewDashboardDesignSetting);
  const newDesignPilotEnabled = useFeatureSwitch("REACT_APP_NEW_DASHBOARD_DESIGN_PILOT_USERS");
  const forceNewDashboardDesign = useFeatureSwitch("REACT_APP_NEW_DASHBOARD_DESIGN_NEW_SIGNUPS_AFTER");

  const updateNewDashboardDesignSetting = useCallback((newValue: NewDashboardDesignSetting) => {
    let cookieOptions: Cookies.CookieAttributes | undefined;
    if (newValue === "no") {
      // store negative answers for 1 week only
      cookieOptions = { expires: 7 };
    }

    // TODO maybe it would be better to store it in localstorage?
    setCookie(cookieName, newValue, cookieOptions);

    trackNewDesignBannerOptionClicked(newValue)

    setNewDashboardDesignSetting(newValue);
  }, []);

  return (
    <NewDashboardDesignContext.Provider value={ {
      newDashboardDesignSetting: forceNewDashboardDesign ? "yes" : (newDesignPilotEnabled ? newDashboardDesignSetting : "no"),
      newDesignForced: forceNewDashboardDesign,
      updateNewDashboardDesignSetting: (!forceNewDashboardDesign && newDesignPilotEnabled) ? updateNewDashboardDesignSetting : () => {}
    } }
    >
      { children }
    </NewDashboardDesignContext.Provider>
  );
};

export function useNewDashboardDesign() {
  return useContext(NewDashboardDesignContext);
}
