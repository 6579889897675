import { isValidEbayProductId } from "components/hostedScrapingValidators";
import { SDEContent } from "./sdecontentTypes";

export const EbayProductContent: SDEContent = {
  projectType: 'async_ebay_product',
  projectCategory: 'ebay',
  apiUrlFn: (apiHost: string) => {
    return `${apiHost}/structured/ebay/product`;
  },
  docsSection: 'ebay-product-page-api',
  docsSectionTitle: 'Ebay Product Page API',
  docsSectionAsync: 'ebay-product-page-api-async',
  docsSectionAsyncTitle: 'Ebay Product Page API (Async)',
  inputKey: 'product_id',
  inputsKey: 'product_ids',
  validatorFunction: isValidEbayProductId,
  newProjectEditProject: {
    inputBoxLabels: {
      listLiteralInputTitle: 'Product ID',
      listLiteralInputPlaceholder: 'Enter one product id per line.',
    },
    inputSectionLabels: {
      inputSectionTitle: "Input product IDs",
      inputSectionDescription: "Enter product IDs in the input field or upload CSV with your product IDs to begin scraping Ebay.",
    },
    projectContent: {
      title: 'Ebay product pages',
      description: 'Extract product details from product pages on Ebay such as product name, price and ratings. Download the results in a well-structured JSON file.',
      icon: 'ebay',
      inputTitle: 'Product id',
      },
  },
  apiPlayground: {
    inputSectionLabels: {
      inputSectionTitle: 'Input product ID',
      inputSectionDescription: 'Enter product IDs to begin scraping Ebay.',
    },
    projectContent: {
      title: 'Ebay product pages',
      description: 'Extract product details from product pages on Ebay such as product name, price and ratings.',
      icon: 'ebay',
    },
    enterInputsPlaceholder: '123213123213',
    enterInputsMessage: 'Enter product Id for scraping',
    codeViewPlaceHolder: 'Please provide a valid product id',
    dropdownTitle: 'Ebay product'
  }
};
