import { useGlobalNavigationProvider } from "providers/NavigationProvider";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const ScrollToHeaderTitle = () => {
    const location = useLocation();
    const { navigationType } = useGlobalNavigationProvider();
    const divRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if ((navigationType === 'PUSH' || navigationType === 'REPLACE') && divRef.current) {
            divRef.current.scrollIntoView({behavior: "auto", block: "start" });
        }
    }, [location.pathname, navigationType]);

    return <div ref={divRef} id="title-header"></div>
}

export default ScrollToHeaderTitle;